import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, Link, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMedia from '@mui/material/CardMedia';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {
    useGridApiRef,
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbar,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridValueGetterParams,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Avatar, CardActions, CardHeader, Collapse, Divider, LinearProgress, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import Notifications from './../components/notifications/Notifications';

import { client, AUTH_USER, CREATE_CREDIT, getUsers, filterRecommendation, filterCredit, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY, getCompanies, UPDATE_COMPANY, getCategories, findProfileByUser, UPDATE_CREDIT, UPDATE_RECOMMENDATION } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { serverConfig } from '../api/apiConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems: 'center',
        height: '100%',
    },
    content: {
        textAlign: 'center',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    listContainer: {
        //maxWidth:'80%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'flex-start',

        maxHeight: 'calc(100vh - 100px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'green',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'darkgreen',
        },
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    addButton: {
        margin: theme.spacing(2),
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    modalContent: {
        margin: "0 auto!Important",
        alignSelf: "center",
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignContent: 'center',
        flexWrap: 'nowrap',
        //alignItems: 'flex-start',
        // justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid green',
        width: '80%!important',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
}));

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const ScoutPage: React.FC<PropsFromRedux> = ({ user, isLoadingUsuarios, isLoadingUsuariosByFilter, isLoadingCreateUsuario, empresas }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [selectedUsuario, setSelectedUsuario] = React.useState('');
    const [addUsuarioModalOpen, setAddUsuarioModalOpen] = React.useState(false);
    const [newUsuarioName, setNewUsuarioName] = React.useState('');
    const [newUsuarioPassword, setNewUsuarioPassword] = React.useState('');
    const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
    const [newUsuarioEmail, setNewUsuarioEmail] = React.useState('');
    const [newUsuarioTelefone, setNewUsuarioTelefone] = React.useState('');
    const [newUsuarioEmpresa, setNewUsuarioEmpresa] = React.useState('');
    const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
    const [usuarios, setUsuarios] = React.useState<any>([]);
    const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
    const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const permissoesList = ['administrador', 'gerente', 'basico']

    const [rows, setRows] = React.useState(usuarios);
    const [categories, setCategories] = React.useState<any>([]);
    const [companies, setCompanies] = React.useState<any>([]);
    const [selectedCategory, setSelectedCategory] = React.useState<any>({});
    const [selectedCompany, setSelectedCompany] = React.useState<any>({});
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [save, setSave] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });

    const [startDate, setStartDate] = React.useState<any>(null);
    const [endDate, setEndDate] = React.useState<any>(null);
    const [expanded, setExpanded] = React.useState('');
    const [link, setLink] = React.useState('');
    const [referralsRemaining, setReferralsRemaining] = React.useState(0);
    const [referralsRequested, setReferralsRequested] = React.useState(0);
    const [recommendations, setRecommendations] = React.useState<any>([]);

    const [referralData, setReferralData] = React.useState<any>({});

    const [referrals, setReferrals] = React.useState<any>([]);
    const [selectedScout, setSelectedScout] = React.useState<any>({});
    const [scoutInfo, setScoutInfo] = React.useState<any>(false);
    const [selectedRecommendation, setSelectedRecommendation] = React.useState<any>({});
    const [credit, setCredit] = React.useState<any>({});
    const [requestPanel, setRequestPanel] = React.useState<any>(false);
    const [requestProposalPanel, setRequestProposalPanel] = React.useState<any>(false);
    const [openPdf, setOpenPdf] = React.useState<any>(false);
    const [openReferral, setOpenReferral] = React.useState<any>(false);
    const [CreateCreditMutation, { data }] = useMutation(CREATE_CREDIT);
    const [UpdateCreditMutation, { data:updateCreditInput }] = useMutation(UPDATE_CREDIT);
    const [UpdateRecommendationMutation, { data: updateRecommendationInput }] = useMutation(UPDATE_RECOMMENDATION);
    const handleAddCredit = async () => {
        const dataToSend = {
            user: user._id,
            active: true,
            amount: 3,
        }
        console.log('dataToSend', dataToSend)

        // dataToSend.id = dataToSend._id

        console.log('dataToSend', dataToSend)

        try {

            const createCreditInput = dataToSend
            console.log('createCreditInput', dataToSend)

            const result = await CreateCreditMutation({
                variables: {
                    createCreditInput
                },
                onCompleted: ({ createCredit }) => {

                    console.log('company created== > ', createCredit);
                    const notData = {
                        type: 'success',
                        message: `Credit ${createCredit?.name} Created Successfully!`,
                        title: 'wescout',
                        timeOut: 2500,
                        callback: () => { },
                        priority: true,
                    }

                    Notifications(notData)

                    //navigate('/login')
                    //setCompany(updateUser);
                }
            });
        } catch (error: any) {
            const notData = {
                type: 'error',
                message: error.message,
                title: 'wescout',
                timeOut: 2500,
                callback: () => { },
                priority: true,
            }
            Notifications(notData)
            console.log("Error at create credit", error)

        }

    };

    const handleExpandClick = (id: string) => {
        const newValue = expanded == id ? '' : id
        setExpanded(newValue);
    };
    const apiRef = useGridApiRef();

    const checkCredit = async (u: any) => {
        setLoading(true)
        const objToSend = {
            //startDate,
            //endDate,
            //empresa:empresa
            //category:selectedCategory?._id || '',
            "user": u._id || '',
        }
        //setIsLoadingCompaniesByFilter(true)
        console.log('objToSend', objToSend)

        const result = await filterCredit(objToSend)
        console.log('filterCredit result ==>>', result)
        if (result.length == 0) {
            console.log('call add credit')
            handleAddCredit()
        } else {
            setCredit(result[0])
            const requested = result[0].recommendations.length
            setReferralsRequested(requested)
            setReferralsRemaining(result[0].amount - requested)
        }
        setLoading(false)
        checkRecommendations(u)
        //return result
        //getProductsByFilter(objToSend)
        //getCompaniesByFilter(objToSend)
        //setIsLoadingCompaniesByFilter(false)
        //setCompanies(result)
        //setSelectedCategory({})
    }
    const checkRecommendations = async (u: any) => {
        setLoading(true)
        const objToSend = {
            //startDate,
            //endDate,
            //empresa:empresa
            //category:selectedCategory?._id || '',
            "scout": u._id || '',
            "active": true,
            //"type": 'scoutme'

        }
        //setIsLoadingCompaniesByFilter(true)
        console.log('objToSend recommendations', objToSend)

        const result: any = await filterRecommendation(objToSend)
        console.log('filterRecommendation result ==>>', result)
        if (result.length == 0) {
            console.log('call add credit')
            //handleAddRecommendations()
        } else {
            //setReferralsRemaining(result[0].amount)
            setRecommendations(result)
        }
        setLoading(false)
        
        //getProductsByFilter(objToSend)
        //getCompaniesByFilter(objToSend)
        //setIsLoadingCompaniesByFilter(false)
        //setCompanies(result)
        //setSelectedCategory({})
    }

    const getCategoriesAsync = async () => {

        const result: any = await getCategories()
        console.log('resutl ===>', result)

        if (result?.length) {
            setCategories(result)
        }
    }

    const getCompaniesAsync = async () => {

        const result: any = await getCompanies()
        console.log('resutl ===>', result)

        if (result?.length) {
            setCompanies(result)
        }
    }

    useEffect(() => {
        if (recommendations.length) {

            apiRef.current.setColumnVisibility('_id', false);
            setRows(recommendations)
        }


    }, [recommendations]);

    /* useEffect(() => {
        changeFilter()

    }, [selectedUser, startDate, endDate,]); */
    useEffect(() => {
        //getUsuarios();
        getCategoriesAsync();
        getCompaniesAsync();

    }, []);
    useEffect(() => {
        if (user?._id) {
            checkCredit(user)
        }

    }, [user]);



    const getUsuarios = async () => {

        const result = await getUsers()
        const users = result
        console.log(users)
        if (users.length) {
            setUsuarios(users)
        }
    }
    useEffect(() => {
        if (isLoadingUsuarios) {
            setLoading(true)
        }
        if (loading && !isLoadingUsuarios) {
            getUsuarios();
            setLoading(false)
        }
        console.log('isLoadingUsuarios ==> ', isLoadingUsuarios)

    }, [isLoadingUsuarios]);

    useEffect(() => {
        if (isLoadingCreateUsuario) {
            setLoading(true)
        }
        if (loading && !isLoadingCreateUsuario) {
            getUsuarios();
            setLoading(false)
        }
        console.log('isLoadingCreateUsuario ==> ', isLoadingCreateUsuario)

    }, [isLoadingCreateUsuario]);

    const handleDeleteClick = (UsuarioId: any) => {
        console.log('UsuarioId', UsuarioId)
        setSelectedUsuario(UsuarioId);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmation = () => {
        console.log('selectedUsuario', selectedUsuario)
        //deleteUsuario(selectedUsuario);
        setDeleteConfirmationOpen(false);
    };

    const clearNewUsuario = () => {
        setNewUsuarioName('');
        setNewUsuarioEmail('');
        setNewUsuarioTelefone('');
        setNewUsuarioEmpresa('');
        setNewUsuarioPassword('');
        setNewUsuarioConfirmPassword('');

    }

    const makePermissoes = () => {
        if (newUsuarioPermissoes == 'administrador') {
            return { admin: true }
        } else if (newUsuarioPermissoes == 'basico') {
            return { basic: true }
        } else if (newUsuarioPermissoes == 'gerente') {
            return { manager: true }
        } else {
            return {}
        }
    }

    const handleAddUsuario = () => {
        const novaUsuario = {
            nome: newUsuarioName,
            email: newUsuarioEmail,
            password: newUsuarioPassword,
            confirmPassword: newUsuarioConfirmPassword,
            empresa: newUsuarioEmpresa || '635c074796a268680647cdff',
            permissoes: makePermissoes(),
            ativo: true,
        };
        console.log('novaUsuario', novaUsuario)
        //createUsuario(novaUsuario);
        setAddUsuarioModalOpen(false);
        clearNewUsuario()
    };


    const validateEmail = (email: any) => {
        return true
        // Implemente a lógica de validação do e-mail aqui
    };






    const validatePhone = (phone: any) => {
        // Remove caracteres não numéricos
        phone = phone.replace(/[^\d]+/g, '');

        // Verifica se possui 11 dígitos (considerando o DDD)
        if (phone.length !== 11) {
            return false;
        }

        // Verifica se todos os dígitos são iguais (ex: 00000000000)
        if (/^(\d)\1+$/.test(phone)) {
            return false;
        }

        return true;
    };

    const maskPhone = (phone: any) => {
        // Remove caracteres não numéricos
        phone = phone.replace(/[^\d]+/g, '');

        // Aplica a máscara
        phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

        return phone;
    };

    const handleEditUsuario = (usuario: any) => {
        console.log('usuario selecionada ==>> ', usuario)
        let perm = 'basico'
        if (usuario.permissoes?.admin) {
            perm = 'administrador'
        } else if (usuario.permissoes?.manager) {
            perm = 'gerente'
        }
        setNewUsuarioPermissoes(perm)
        setPasswordOpen(false);
        setNewUsuarioPassword('')
        setNewUsuarioConfirmPassword('')
        setEditUsuarioData(usuario);
        setEditUsuarioModalOpen(true);
    };

    const handleUpdateUsuario = () => {
        console.log(newUsuarioPassword)
        const dataToSend = { ...editUsuarioData }
        if (!newUsuarioPassword) {
            console.log('editUsuarioData', editUsuarioData)
            delete dataToSend.password
        }
        dataToSend.permissoes = makePermissoes();
        console.log('dataToSend', dataToSend)
        //updateUsuario(dataToSend)
        setEditUsuarioData({});
        setEditUsuarioModalOpen(false);
        // Lógica para atualizar a Usuario com os dados do modal
    };

    const validatePassword = (password: string | any[]) => {
        return password.length >= 6;
    };

    const validateConfirmPassword = (password: any, confirmPassword: any) => {
        return password === confirmPassword;
    };



    //init DataGrid dependencies

    const clearData = (d: any) => {
        const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
        return dateTransform
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    interface EditToolbarProps {
        setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setRowModesModel: (
            newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
        ) => void;
    }

    const _ = (id: GridRowId) => () => {
        setRows(rows.filter((row: any) => row._id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row: any) => row._id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row: any) => row._id !== id));
        }
    };

    const processRowUpdate: any = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log('newRow', newRow)
        if (newRow.isNew) {
            delete newRow.isNew
        }
        setEditUsuarioData(newRow)
        setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const checkPermissao = (perm: any) => {
        let permString = ''
        if (perm.admin) {
            permString = 'administrador'
        } else if (perm.manager) {
            permString = 'gerente'
        } else {
            permString = 'operador'
        }
        return permString

    }



    const getProfileByUserAsync = async (userId: any) => {
        try {
            setLoading(true)
            const profile: any = await findProfileByUser(userId);
            console.log('profile ==>>', profile)
            setLoading(false)
            setEditUsuarioData(profile)
        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
    }

    const showRequest = async (refer: any) => {

        console.log('refer==>', refer)
        //return;
        setReferralData(refer.referral)
        const recom = refer
        console.log('recom', recom)
        await getProfileByUserAsync(refer.scout._id)
        setSelectedRecommendation(recom)
        setSelectedScout(user)
        setRequestPanel(true)
        setOpenReferral(true)
        //handleAddReferral(recom)
        //setRequestPanel(true)

    }
    const showRequestProposal = async (recom: any) => {


        console.log('recom', recom)
        await getProfileByUserAsync(recom.scout._id)
        setSelectedRecommendation(recom)
        setSelectedScout(user)
        setRequestProposalPanel(true)


    }


    const columns: GridColDef[] = [
        { field: '_id', headerName: 'ID', width: 90 },
        {
            field: 'createdAt',
            headerName: 'Date',
            type: 'Date',
            width: 140,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
        },

        {
            field: 'company',
            headerName: 'Company',
            width: 150,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => params.row.company?.name || ""
        },
        {
            field: 'scouter',
            headerName: 'Scouter',
            width: 150,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => params.row.scouter?.name || ""
        },
        {
            field: 'link',
            headerName: 'Job link',
            width: 150,
            editable: true,
            //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: false,
            //valueGetter: (params: GridValueGetterParams) => params.row.role || ''
        },

        {
            field: 'details',
            headerName: 'Details',
            width: 120,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => params.row.details || ''
        },



        /* {
          field: 'identificacao',
          headerName: 'código',
          description: 'código do item',
          sortable: false,
          width: 140,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.identificacao[0].codigo || ''}  `,
        }, 
        {
          field: 'updatedAt',
          headerName: 'tipo identificacao',
          description: 'tipo de identificacao',
          sortable: false,
          width: 75,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.identificacao[0].tipo || ''} `,
        }, */
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                const cat = usuarios.filter((item: any) => item._id == id)[0]
                const item = rows.filter((item: any) => item._id == id)[0]
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                        //onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [



                    <GridActionsCellItem
                        icon={
                            item.status == 'Under Review' && item.type == 'scoutingpool'&&item.details!="Under Review by Scouter" ?
                                <Tooltip TransitionComponent={Zoom} title="View request" arrow>
                                    <IconButton className='iconDetail' color="primary" aria-label="Details" >
                                        <AppRegistrationIcon />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip TransitionComponent={Zoom} title="Details" arrow>
                                    <IconButton className='iconDetail' color="primary" aria-label="Details" >
                                        <ViewHeadline />
                                    </IconButton>
                                </Tooltip>

                        }
                        label="Details"
                        className="textPrimary"
                        onClick={() => {

                            if (item.status == 'Under Review' && item.type == 'scoutingpool'&&item.details!="Under Review by Scouter") {
                                showRequestProposal(item)
                                return
                            }
                            if (item.status == 'Under Review') {
                                const notData = {
                                    type: 'success',
                                    message: `Your referral has been made to ${item.company.name} members and is awaiting feedback.`,
                                    title: 'wescout',
                                    timeOut: 1000,
                                    callback: () => { },
                                    priority: true,
                                }

                                return Notifications(notData)
                            }
                            showRequest(item)
                            //console.log(item)
                        }}
                        color="inherit"
                    />,

                ];
            },
        },


    ];

    const hiddenFields = ['_id', 'id', 'ID'];

    const getTogglableColumns = (columns: GridColDef[]) => {
        console.log('getTogglableColumns', columns)
        return columns
            .filter((column) => !hiddenFields.includes(column.field))
            .map((column) => column.field);
    };

    const changeFilter = async (cat?: any) => {
        const referencia = cat || selectedCategory
        const objToSend = {
            startDate,
            endDate,
            //empresa:empresa
            category: referencia?._id || '',
            //usuario : selectedUser?._id || '',

        }
        //setIsLoadingCompaniesByFilter(true)
        console.log('objToSend', objToSend)

        const result = await filterCompany(objToSend)
        console.log('filter result ==>>', result)
        //getProductsByFilter(objToSend)
        //getCompaniesByFilter(objToSend)
        //setIsLoadingCompaniesByFilter(false)
        setCompanies(result)
        //setSelectedCategory({})
    }

    const changeCategory = (cat: any) => {
        console.log(cat)
        changeFilter(cat)
    }
    const handleSendLink = () => {
        console.log()
    }


    const handleUpdateCredit = async ()=>{
        const dataToSend = {
            _id: credit._id,
            recommendationId : selectedRecommendation._id
        }
        console.log('handleUpdateCredit dataToSend', dataToSend)

        // dataToSend.id = dataToSend._id

        //return;

        try {

            const updateCreditInput = dataToSend
            console.log('updateCreditInput', dataToSend)

            const result = await UpdateCreditMutation({
                variables: {
                    updateCreditInput
                },
                onCompleted: ({ createCredit }) => {

                    console.log('company created== > ', createCredit);
                    const notData = {
                        type: 'success',
                        message: `Created Successfully!`,
                        title: 'wescout',
                        timeOut: 1500,
                        callback: () => { },
                        priority: true,
                    }

                    Notifications(notData)
                    checkCredit(user)

                    //navigate('/login')
                    //setCompany(updateUser);
                }
            });
        } catch (error: any) {
            const notData = {
                type: 'error',
                message: error.message,
                title: 'wescout',
                timeOut: 2500,
                callback: () => { },
                priority: true,
            }
            Notifications(notData)
            console.log("Error at create credit", error)

        }
    }

    const updateMutation = async () => {
        setLoading(true);
        const dataToSend = {
          _id: selectedRecommendation._id,
          status: "Under Review",
          details: 'Under Review by Scouter',
          detailsScouter: 'Need your action',
          //referral: referralId,
          //scouter: user._id
        }
        try {
    
          const updateRecommendationInput = dataToSend
          console.log('updateRecommendationInput', dataToSend)
    
          const result = await UpdateRecommendationMutation({
            variables: {
              updateRecommendationInput
            },
            onCompleted: ({ updateRecommendation }) => {
    
              console.log('updateRecommendation== > ', updateRecommendation);
              const notData = {
                type: 'success',
                message: `Referral to ${updateRecommendation?.company?.name} Created Successfully!`,
                title: 'wescout',
                timeOut: 1000,
                callback: () => { },
                priority: true,
              }
              //checkReferrals(user)
              //Notifications(notData)
              setLoading(false);
              handleUpdateCredit()
              //checkRecommendations(user)
              //navigate('/scout')
              //setCompany(updateUser);
            }
          });
        } catch (error: any) {
          const notData = {
            type: 'error',
            message: error.message,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }
          Notifications(notData)
          console.log("Error at updateRecommendation", error.message)
          setLoading(false);
        }
      }

    const acceptRecommendation = ()=>{
        console.log('acceptRecommendation', selectedRecommendation)
        
        
        updateMutation()
        setRequestProposalPanel(false)
    }
    const denyRecommendation = ()=>{
        console.log('denyRecommendation')
        setRequestProposalPanel(false)
    }





    return (
        <Container className={classes.container}>
            <div className='{classes.root}'>
                <div className={classes.content}>
                    {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}

                    {/* <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddUsuarioModalOpen(true)}>
            add user
          </Button> */}

                    {loading ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : null}
                    <Box sx={{ width: '100%', height: 30, paddingTop: '15px' }}>
                        <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} >
                            <Typography className={'title'}>
                                Referrals you’ve requested
                            </Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ width: '100%', height: 80, }} style={{
                        padding: '10px', paddingBottom: '10px',
                        borderColor: 'green',
                        borderWidth: '2px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignContent: 'center',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }}
                    >

                        <Button onClick={() => {
                            const notData = {
                                type: 'success',
                                message: `Request a referral now! Go to Scout me page`,
                                title: 'wescout',
                                timeOut: 1000,
                                callback: () => { },
                                priority: true,
                            }

                            return Notifications(notData)
                        }} variant="contained" className='button-referral'  >
                            Referrals Remaining
                            <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                                {referralsRemaining}
                            </Avatar>

                        </Button>
                        <Button variant="contained" className='button-referral'  >
                            Referrals Requested
                            <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                                {referralsRequested}
                            </Avatar>

                        </Button>
                    </Box>
                    <Box sx={{ width: '100%', height: 100, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>

                        {/* <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                            <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 30, alignContent: 'center', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                                <Autocomplete
                                    getOptionLabel={(cat: any) => cat.name}
                                    //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                                    onChange={(event, newValue) => {
                                        console.log(newValue)


                                        if (!newValue) {
                                            setSelectedCategory({})
                                            changeCategory({})

                                        }
                                        setSelectedCategory(newValue)
                                        changeCategory(newValue)
                                        //getInventoriesByCategory(newValue._id)
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={categories}
                                    sx={{ width: 300 }}
                                    renderInput={(params: any) => <TextField {...params} label="Category" />}
                                />
                                <Autocomplete
                                    getOptionLabel={(usu: any) => usu.name}
                                    //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        setSelectedCompany(newValue)
                                        //changeFilter()

                                    }}
                                    disablePortal
                                    id="combo-box-usuario"
                                    options={companies}
                                    sx={{ width: 300 }}
                                    renderInput={(params: any) => <TextField {...params} label="Company" />}
                                />

                            </Box>

                           







                        </Stack> */}
                    </Box>


                    {recommendations.length ? (
                        <div className={classes.listContainer}>

                            <DataGrid
                                apiRef={apiRef}
                                loading={isLoadingUsuarios || isLoadingUsuariosByFilter}
                                getRowId={(row: { _id: any; }) => row._id}
                                //checkboxSelection
                                disableRowSelectionOnClick

                                onClipboardCopy={(copiedString) => console.log(copiedString)}
                                unstable_ignoreValueFormatterDuringExport
                                autoHeight={true}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'createdAt', sort: 'asc' }],
                                    },

                                    pagination: {
                                        paginationModel: {
                                            pageSize: 15,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                //checkboxSelection

                                //editMode="row"
                                rowModesModel={rowModesModel}
                                onRowModesModelChange={handleRowModesModelChange}
                                onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                // slots={{
                                //   toolbar: EditToolbar,
                                // }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        setRows, setRowModesModel, csvOptions: {
                                            fileName: 'usuariosDataBase_',
                                            delimiter: ';',
                                            utf8WithBom: true,
                                        },
                                        columns: {
                                            columnVisibilityModel: { ID: false, },
                                        },
                                        columnsPanel: {
                                            getTogglableColumns,
                                        },
                                        printOptions: { hideToolbar: true, disableToolbarButton: false }
                                    },
                                }}



                            //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },


                            />

                        </div>
                    ) : (<Box>
                        <Typography paragraph>
                            Request a referral now
                        </Typography>
                        <Button onClick={() => navigate('/scoutme')}>Scout me</Button>
                    </Box>)}

                    {/* {usuarios.length ? (
            <div className={'listContainer'}>
              <List>
                {usuarios.map((usuario: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{usuario.nome}</Typography>
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handleEditUsuario(usuario)}>
                        <EditIcon />
                      </IconButton>
                      
                      <IconButton
                        color="secondary"
                        aria-label="Deletar"
                        className={classes.deleteButton}
                        onClick={() => handleDeleteClick(usuario._id)}
                      >
                        
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
                    {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
                </div>
            </div>

            <Dialog fullWidth={true} style={{ minWidth: '600px', width: '80vw', margin: "0 auto" }} open={requestProposalPanel} onClose={() => {
                setRequestProposalPanel(false)
                //setOpenReferralPro(false)
            }} >

                <DialogTitle>Accept or deny this request - complete the following information </DialogTitle>

                <DialogContent className={classes.modalContent}>
                    <Typography variant="h4">{selectedRecommendation?.company?.name || ""}</Typography>

                    {/* <Typography variant="body1">E-mail of {selectedRecommendation.company?.name || ""} HR</Typography> */}

                    <>


                        <Typography variant="body2">send at: {clearData(selectedRecommendation.createdAt)}</Typography>
                        {/* <Typography variant="body1">Thank you for your referral!</Typography> */}
                        <Typography variant="body2">send by: {selectedRecommendation?.scouter?.name}</Typography>
                        <Typography variant="body2">message: {selectedRecommendation.message}</Typography>
                    </>


      
                    <div>
                        
                       
                        <Divider />
                        {/* <CircularProgressWithLabel style={{ margin: 15 }} value={80} /> */}
                        <Divider />
                        <div style={{ fontSize: 18, margin: 15 }}>
                            <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
                                {'Job link'}
                            </Link>

                        </div>

                        <Typography>
                        By accepting this proposal, your referral will be sent to the company's HR department. This accept will be consumed 1 credit.
                        </Typography>

                        {/* {limit ? (<CircularProgressWithLabel style={{ margin: 15 }} value={progress} />) : null} */}


                    </div>
                </DialogContent>
                <DialogActions>
                    

                        <Button variant='outlined' onClick={() => {
                            acceptRecommendation()
                        }}>Accept</Button>
                        <Button color="secondary" variant='outlined' onClick={() => {
                            denyRecommendation()
                        }}>Deny</Button>
                        <Button onClick={() => setRequestProposalPanel(false)}>Cancel</Button>
                    
                </DialogActions>
            </Dialog>
            <Dialog fullWidth={true} style={{ minWidth: '600px', width: '80vw', margin: "0 auto" }} open={requestPanel} onClose={() => {
                setRequestPanel(false)
                setOpenReferral(false)
            }} >
                {!openReferral ?
                    <DialogTitle>Accept request - complete the following information </DialogTitle>
                    : <DialogTitle>Referral complete </DialogTitle>}
                <DialogContent className={classes.modalContent}>
                    <Typography variant="h4">{selectedRecommendation?.company?.name || ""}</Typography>

                    {/* <Typography variant="body1">E-mail of {selectedRecommendation.company?.name || ""} HR</Typography> */}

                    {!openReferral ?
                        <>
                            <TextField
                                style={{ marginBottom: 2 }}
                                variant="outlined"
                                hiddenLabel
                                label={`E-mail of ${selectedRecommendation?.company?.name} HR`}
                                /* multiline
                                maxRows={4} */
                                value={referralData.email || ""}
                                onChange={(e) => setReferralData({ ...referralData, email: e.target.value })}
                            />
                            <TextField
                                style={{ marginBottom: 2 }}
                                variant="outlined"
                                hiddenLabel
                                label="Intro text for your nomination"
                                multiline
                                maxRows={4}
                                value={referralData.message || ""}
                                onChange={(e) => setReferralData({ ...referralData, message: e.target.value })}
                            />
                        </> : <>


                            <Typography variant="body2">send at: {clearData(referralData.createdAt)}</Typography>
                            <Typography variant="body1">Thank you for your referral!</Typography>
                            <Typography variant="body2">send to: {referralData.email}</Typography>
                            <Typography variant="body2">message: {referralData.message}</Typography>
                        </>

                    }
                    {/* <Typography variant="body1">Intro text for your nomination</Typography>
          <input
            
            type="text"
            value={referralData.message}
            onChange={(e) => setReferralData({ ...referralData, message: e.target.value })}
          /> */}
                    <Typography variant="body1">Location: {editUsuarioData.location}</Typography>
                    {/*  <input
            type="text"

            value={editUsuarioData.location}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, location: e.target.value })}
          /> */}
                    <div>
                        <Divider />
                        <Button onClick={() => setOpenPdf(true)}>Curriculum(PDF Viewer)</Button>
                        <Divider />
                        <div style={{ fontSize: 18, margin: 15 }}>

                            <TextField

                                style={{ marginBottom: 5 }}
                                variant="outlined"
                                hiddenLabel
                                label="bio"
                                multiline
                                maxRows={4}
                                value={editUsuarioData.bio || ""}
                            //  onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
                            />
                        </div>
                        <Divider />
                        {/* <CircularProgressWithLabel style={{ margin: 15 }} value={80} /> */}
                        <Divider />
                        <div style={{ fontSize: 18, margin: 15 }}>
                            <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
                                {'Job link'}
                            </Link>

                        </div>

                        {/* {limit ? (<CircularProgressWithLabel style={{ margin: 15 }} value={progress} />) : null} */}


                    </div>
                </DialogContent>
                <DialogActions>
                    {

                        <Button variant='outlined' onClick={() => {
                            setOpenReferral(false)
                            setRequestPanel(false)
                        }}>Close</Button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                <DialogTitle>Confirm </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={addUsuarioModalOpen} onClose={() => setAddUsuarioModalOpen(false)}>
                <DialogTitle>Adicionar Usuario</DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <TextField
                        label="Name"
                        value={newUsuarioName}
                        onChange={(e) => setNewUsuarioName(e.target.value)}
                    />
                    <TextField
                        label="E-mail"
                        value={newUsuarioEmail}
                        onChange={(e) => setNewUsuarioEmail(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        error={!validateEmail(newUsuarioEmail)}
                        helperText={!validateEmail(newUsuarioEmail) && 'E-mail inválido'}
                    />
                    <TextField
                        label="Password"
                        value={newUsuarioPassword}
                        onChange={(e) => {
                            setNewUsuarioPassword(e.target.value)
                        }}
                        type="password"
                        error={!validatePassword(newUsuarioPassword)}
                        helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
                    />
                    <TextField
                        label="Confirm password"
                        value={newUsuarioConfirmPassword}
                        onChange={(e) => {
                            setNewUsuarioConfirmPassword(e.target.value)
                        }}
                        type="password"
                        error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
                        helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
                    />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
                        <Select
                            value={newUsuarioEmpresa}
                            onChange={(e: any) => setNewUsuarioEmpresa(e.target.value)}
                            inputProps={{
                                name: 'empresa',
                                id: 'empresa-select',
                            }}
                        >
                            {empresas.map((empresa: { _id: any; nome: any; }) => (
                                <MenuItem key={empresa._id} value={empresa._id}>
                                    {empresa.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
                        <Select
                            value={newUsuarioPermissoes}
                            onChange={(e: any) => setNewUsuarioPermissoes(e.target.value)}
                            inputProps={{
                                name: 'permissao',
                                id: 'permissao-select',
                            }}
                        >
                            {permissoesList.map((permissao: any) => (
                                <MenuItem key={permissao} value={permissao}>
                                    {permissao}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddUsuarioModalOpen(false)}>Cancelar</Button>
                    <Button onClick={handleAddUsuario} color="primary">
                        Criar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="lg" open={selectedCompany && selectedCompany?.name} onClose={() => setSelectedCompany({})}>
                <DialogTitle>  Paste a link to the job listing (with job description) by: {selectedCompany?.name}</DialogTitle>
                <DialogContent className={classes.modalContent}>

                    <TextField
                        label="Paste the link here"
                        value={editUsuarioData.email}
                        onChange={(e) => setLink(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Link />
                                </InputAdornment>
                            ),
                        }}
                        error={!validateEmail(editUsuarioData.email)}
                        helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
                    />



                    {/* <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newUsuarioPermissoes}
                onChange={(e: any) => setNewUsuarioPermissoes(e.target.value)}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedCompany({})}>Cancelar</Button>
                    <Button onClick={handleSendLink} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>


            {/* <Modal open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editUsuarioData.nome}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.email}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.telefone}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.cnpj}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateUsuario}>Atualizar</button>
  </div>
</Modal> */}
        </Container>
    );
};

const mapStateToProps = (state: any) => ({

    user: state.user.user,
    isLoadingUsuarios: state.usuarios.isLoadingUsuarios,
    isLoadingUsuariosByFilter: state.usuarios.isLoadingUsuariosByFilter,
    isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
    empresas: state.empresas.empresas
});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScoutPage);
