import React, { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route, useNavigate,useLocation  } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import store from './redux/store';
import HomePage from './pages/HomePage';
import UsuarioPage from './pages/UsuarioPage';
import CodePage from './pages/CodePage';
import PasswordPage from './pages/PasswordPage';
import ReferralPage from './pages/ReferralPage';
import AdminPage from './pages/AdminPage';
import ScoutingPoolPage from './pages/ScoutingPoolPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './App.css';
import LoginPage from './pages/LoginPage';
import CompaniesPage from './pages/CompaniesPage';
import EmpresaPerfilPage from './pages/EmpresaPerfilPage';
import UsuariosPage from './pages/UsuariosPage';
import AccountPage from './pages/AccountPage';
import ScoutMePage from './pages/ScoutMePage';
import JobboardPage from './pages/JobboardPage';
import JobboardAdmPage from './pages/JobboardAdmPage';
import AiVisaGuidePage from './pages/AiVisaGuidePage';
import AiInterviewSimulation from './pages/AiInterviewSimulation';
import AiResumeFeedback from './pages/AiResumeFeedback';
import ProdutosPage from './pages/ProdutosPage';
import ScoutersPage from './pages/ScoutersPage';
import ScoutPage from './pages/ScoutPage';
import CarrerGuidePage from './pages/CarrerGuidePage';
import CategoriasPage from './pages/CategoriesPage';
import LogsPage from './pages/LogsPage';
import SignUpPage from './pages/SignUpPage';
import MapaPage from './pages/MapaPage';
import LocationPage from './pages/LocationPage';
import DashboardPage from './pages/Dashboard';
import NotificationsPage from './pages/NotificationsPage';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { updateUserPosition } from './redux/user/actions';
import { getProdutos, getProdutosByCategory } from './redux/produtos/actions';
import { socket } from './socket';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Admin, Resource } from 'react-admin';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/icons-material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import List from '@material-ui/core/List';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';
import { toggleDrawerStart } from './redux/global/actions';
import bg from "./assets/images/bg.png"
import WaitListPage from './pages/WaitListPage';

type PropsFromRedux = ConnectedProps<typeof connector>;



const App: React.FC<PropsFromRedux> = ({ isLogged, user, updateUserPosition, getProdutos }) => {
  //function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    //setOpen(!open);
    dispatch(toggleDrawerStart())
  };
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState<any>([]);
  //

  //
  /* const client = new ApolloClient({
    uri: 'http://localhost:3000/graphql',
    cache: new InMemoryCache(),
  }); */
  //
  /* client
  .query({
    query: gql`
      query getUsers{users{name, email, _id}}
    `,
  })
  .then((result) => console.log(result)); */
  const createNotification = (type: any) => {
    console.log('createnotification', type)

    switch (type) {
      case 'info':
        NotificationManager.info('Info message');
        break;
      case 'success':
        NotificationManager.success('Success message', 'Greentag');
        break;
      case 'warning':
        NotificationManager.warning('Warning message', 'Close after 3000ms', 3000);
        break;
      case 'error':
        NotificationManager.error('Error message', 'Click me!', 5000, () => {
          alert('callback');
        });
        break;

    };
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop: any) => prop !== 'open',
  })
    <{
      open?: boolean;
    }>
    (({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

  useEffect(() => {
    console.log('isLogged', isLogged)
    console.log('pathname', pathname)
    console.log('search', search)
    if (!isLogged&&pathname==='/password') {
      navigate('/password'+search);
    } else if (!isLogged&&pathname==='/referral' ) {
      navigate('/referral'+search);
    } else if (isLogged&&!user?.confirmed ) {
      navigate('/code');
    } else if (isLogged&&user?.role == "scout") {
      navigate('/scout');
    } else if (isLogged&&user?.role == "scouter") {
      navigate('/scouter');
    } else if (isLogged&&user.name) {
      navigate('/');
    } else {
      navigate('/login');
    }
  }, [isLogged]);


  useEffect(() => {
    function onConnect() {
      console.log('conecou no socket')
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value: any) {
      console.log('receiveMessage', value)
      NotificationManager.success(value.message);
      if (value.type == 'produto' && value.origin == 'mobile') {
        getProdutos()
      }
      setFooEvents((previous: any) => [...previous, value.message]);
    }
    function onUpdateLocation(value: any) {
      console.log('receiveMessage onUpdateLocation', value)
      //NotificationManager.info(value.message);
      updateUserPosition(value)
    }

    /* socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('receiveMessage', onFooEvent);
    socket.on('receiveUpdatePosition', onUpdateLocation);
    socket.connect();
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('receiveMessage', onFooEvent);
      socket.off('receiveUpdatePosition', onUpdateLocation);
    }; */
  }, []);

  const drawerWidth = 240;

  return (
    <div className="app">
      <NotificationContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>


        <Header />
        {/* <div className='bgcontent'>
          <img  width={'100%'} src={bg} alt="bg_wescout" />
        </div> */}
        <div className="main-content">

          {
            isLogged&&user.confirmed ? (<IconButton style={{
              height: 50, width: 50, position: 'absolute',
              top: '90px', left: '30px'
            }} onClick={() => toggleDrawer()}><ChevronRightIcon fontSize="inherit" /></IconButton>) : null
          }

          {/* <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge  color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar> */}


          <Routes>
            {isLogged && user?.name && user.confirmed ? (
              <Route path="/" element={user.role == "admin" ? <HomePage /> : <ScoutersPage />} />
            ) : null}
            {isLogged && user?.name && !user.confirmed ? (
              <Route path="/" element={<CodePage />} />
            ) : null}
            <Route path="/referral" element={<ReferralPage />} />
            <Route path="/password" element={<PasswordPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/location" element={<LocationPage />} />
            <Route path="/signup/:type" element={<SignUpPage />} />
            <Route path="/usuario" element={<UsuarioPage />} />
            <Route path="/code" element={<CodePage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/companies" element={<CompaniesPage />} />
            <Route path="/categories" element={<CategoriasPage />} />
            <Route path="/carrerguide" element={<CarrerGuidePage />} />
            <Route path="/empresa" element={<EmpresaPerfilPage />} />
            <Route path="/users" element={<UsuariosPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/produtos" element={<ProdutosPage />} />
            <Route path="/scouter" element={<ScoutersPage />} />
            <Route path="/scout" element={<ScoutPage />} />
            <Route path="/logs" element={<LogsPage />} />
            <Route path="/scoutme" element={<ScoutMePage />} />
            <Route path="/jobboard" element={<JobboardPage />} />
            <Route path="/jobboardadm" element={<JobboardAdmPage />} />
            <Route path="/aivisaguide" element={<AiVisaGuidePage />} />
            <Route path="/airesumefeedback" element={<AiResumeFeedback />} />
            <Route path="/aiinterviewsimulation" element={<AiInterviewSimulation />} />
            <Route path="/map" element={<MapaPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/scoutingpool" element={<ScoutingPoolPage />} />
            <Route path="/waitlist" element={<WaitListPage />} />
          </Routes>


        </div>

        <Footer />
      </LocalizationProvider>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  isLogged: state.auth.isLogged,
  user: state.user.user
});

const mapDispatchToProps = {
  updateUserPosition,
  getProdutos
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(App);