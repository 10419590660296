import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from '../redux/usuarios/actions';
import { setUser } from '../redux/user/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { deleteUsuario } from '../redux/usuarios/actions';
import { useNavigate } from 'react-router-dom';
import { serverConfig } from '../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, MenuItem, Modal, Stack } from '@mui/material';
import { client, findProfileByUser, CONFIRM_CODE, AUTH_USER, CREATE_PROFILE, filterProfile, getUsers, whoami, UPDATE_USER, UPDATE_PROFILE } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { UploadFile } from '@mui/icons-material';
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Notifications from '../components/notifications/Notifications';
const workerUrl = new URL(
    'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
    import.meta.url,
).toString();
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems: 'center',
        height: '100%',
    },
    content: {

        textAlign: 'center',
    },
    modalContent: {
        margin: "0 auto!Important",
        alignSelf: "center",
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignContent: 'center',
        flexWrap: 'nowrap',
        //alignItems: 'flex-start',
        // justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid green',
        width: '80%!important',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
    form_content: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        aligContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    title: {
        marginBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const CodePage: React.FC<PropsFromRedux> = ({ setImageProfile, setUser, user }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
    const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
    const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>('');
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const permissoesList = ['administrador', 'gerente', 'basico']
    const [contador, setContador] = React.useState(0);
    const [file, setFile] = React.useState<File>();
    const [image, setImage] = React.useState<any>('');
    const [open, setOpen] = React.useState<any>(false);
    const [update, setUpdate] = React.useState<any>(false);
    const [hasProfile, setHasProfile] = React.useState<any>(false);
    const [usuario, setUsuario] = React.useState<any>({});
    const [openPdf, setOpenPdf] = React.useState<any>(false);


    const [ConfirmCode, { data }] = useMutation(CONFIRM_CODE);
    const [UpdateProfileMutation, { data: updateProfileData }] = useMutation(UPDATE_PROFILE);
    const fields = [

        'Bio', //(Descrição como colocamos no LinkedIn (About me);
        'Professional title',
        'Years of experience',
        'Location',
        'Desired companies (3 max)',
        'Open to relocation',
        'Enable Scouting Pool',];
    //  ( Para permitir scouting pool onde referenciadores (scouters)  de qualquer empresa possam entrar em contato para indicá-lo, preencha todo o seu perfil, incluindo a adição de um currículo primeiro, dessa forma, o usuário só vai conseguir permitir o scouting pool depois de completar todo o cadastro, incluindo upload do CV (documento fundamental para essa “ferramenta” funcionar.

    // Neste momento informar o candidato da importância em praticar com a simulação das entrevistas por conta nota que ele vai obter durante a entrevista que será apresentada junto com a nota atribuída pela análise do CV sempre que o scouter colar um URL de vaga contendo um descritivo de uma vaga qd ele estiver fazendo sua busca pelos melhores candidatos.

    // Dessa forma, a “economia” do projeto se beneficia uma vez que ele vai precisar ter uma conta premium para poder praticar mais vezes e ter uma nota melhor para ser mostrada.

    // Campo para subir o CV



    /* useEffect(() => {
      console.log("imagesByUser useEffect", imagesByUser)
      const imagesList:any = [];
      let cont = 0
      imagesByUser.map((item:any, index:any)=>{
        cont = (cont < item.contador ? item.contador : cont)
        const slide = {
          key: index,
          title: 'Usuario',
          text: '',
          image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
          backgroundColor: 'green',
          
        }
        imagesList.push(slide)
      })
      console.log('imagesList ==>>> ', imagesList)
      console.log('cont ==>>> ', cont)
      setContador(cont)
      if(imagesList.length){
        const image = imagesList[0].image
        setImage(image)
        setImageProfile(image)
      }
      //setSlides(imagesList)
     
    },[imagesByUser]); */


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]))
        }
    };



    const handleFileChangePdf = (event: any) => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024; // in MB
        const fileType = file.type;
        setLoading(true)
        if (fileType !== 'application/pdf') {
            alert('Por favor, selecione apenas arquivos PDF.');
            event.target.value = null; // limpa o valor do input
        } else if (fileSize > 10) {
            alert('O arquivo selecionado é muito grande. Por favor, selecione um arquivo de até 10MB.');
            event.target.value = null; // limpa o valor do input
        } else {
            // processa o arquivo...
            setFile(event.target.files[0]);
        }
        setLoading(false)
    };

    const getProfileByUserAsync = async (userId: any) => {
        setLoading(true)
        try {
            const profile: any = await findProfileByUser(userId);
            console.log('profile ==>>', profile)
            if (!profile) {
                setHasProfile(false)
            } else {
                setHasProfile(true)
                setEditUsuarioData(profile)
            }


        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    const getUser = async () => {
        setLoading(true)
        try {
            const user: any = await whoami();
            console.log('user ==>>', user)
            setUsuario(user);
            getProfileByUserAsync(user._id)
        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    /* useEffect(() => {

        getUser();

    }, []) */
    useEffect(() => {

        if (!user.confirmed) {
            setOpen(true)
        }

        if(user._id){
            setEditUsuarioData(user)
        }
        console.log('user code', user);
        //setEditUsuarioData(usuario)
        if (user?.image) {
            const imagePath = serverConfig.dev.url + 'images/' + user.image;
            setImage(imagePath)
            setImageProfile(imagePath)
            console.log('configurou o image profile')
        }
        //if (user && user.name) setUser(user)
    }, [user])

    const makePermissoes = () => {
        if (newUsuarioPermissoes == 'administrador') {
            return { admin: true }
        } else if (newUsuarioPermissoes == 'basico') {
            return { basic: true }
        } else if (newUsuarioPermissoes == 'gerente') {
            return { manager: true }
        } else {
            return {}
        }
    }

    const createUser: any = async (updateUserInput: any) => {
        setLoading(true)
        let variables = { "updateUserInput" : updateUserInput }
        console.log('updateUserInput', updateUserInput)

        try {
            const userToken = await ConfirmCode({
                variables,
                onCompleted: ({ confirmCode }) => {

                    console.log('user result code== > ', confirmCode);
                    if(confirmCode.confirmed){
                        setUser(confirmCode)
                        const notData = {
                            type: 'success',
                            message: `E-mail confirmed successfully!`,
                            title: 'wescout',
                            timeOut: 2000,
                            callback: () => { },
                            priority: true,
                        }
                        if(user.role=='scout'){
                            navigate('/scout')
                        }
                        //checkReferrals(user)
                        Notifications(notData)
                    }else{
                        const notData = {
                            type: 'Error',
                            message: `Invalid code!`,
                            title: 'wescout',
                            timeOut: 2000,
                            callback: () => { },
                            priority: true,
                        }
                        //checkReferrals(user)
                        console.log('codigo invalido')
                        Notifications(notData)
                    }
                    
                    //getProfileByUserAsync(usuario._id)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error)

        }
        setLoading(false)

    }
    const updateUser: any = async (updateProfileInput: any) => {
        setLoading(true)
        let variables: {}
        variables = { updateProfileInput }

        try {
            const userToken = await UpdateProfileMutation({
                variables,
                onCompleted: ({ updateProfile }) => {

                    console.log('updateProfile result== > ', updateProfile);
                    setUsuario(updateProfile);
                    const notData = {
                        type: 'success',
                        message: `Account updated successfully!`,
                        title: 'wescout',
                        timeOut: 2500,
                        callback: () => { },
                        priority: true,
                    }
                    //checkReferrals(user)
                    Notifications(notData)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error)
            const notData = {
                type: 'error',
                message: error.message,
                title: 'wescout',
                timeOut: 2500,
                callback: () => { },
                priority: true,
            }
            Notifications(notData)

        }
        setLoading(false)

    }

    const handleUpdate = async () => {
        //setUpdate(true)
        console.log('editUsuarioData', editUsuarioData)
        const dataToSend = { _id : editUsuarioData._id , code: editUsuarioData.code }
        
        createUser(dataToSend)
         
        
        
        //console.log('dataToSend', dataToSend)



    };

    const validateEmail = (email: any) => {
        return true
        // Implemente a lógica de validação do e-mail aqui
    };

    const validatePassword = (password: string | any[]) => {
        return password.length >= 6;
    };

    const validateConfirmPassword = (password: any, confirmPassword: any) => {
        return password === confirmPassword;
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container className={classes.container}>
            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Complete the requirements to join our Scouting Pool"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please, send your complete CV in PDF format to receive your feedback.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog> */}
            <div className={'content'}>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}

                {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
                {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
                <div className={classes.form_content}>
                    <Box sx={{ width: '100%', paddingTop: '15px' }}>
                        <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} >
                            <Typography className={'title'}>
                                Validate your company e-mail
                            </Typography>
                        </Stack>
                        <Stack marginBottom={5} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} >
                            <Typography>
                                Please, digit the code sent to {user.email}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>

                    </Box>
                    <TextField
                        style={{ marginBottom: 5 }}
                        variant="outlined"
                        hiddenLabel
                        label="code"
                        multiline
                        maxRows={4}
                        value={editUsuarioData.code || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, code: e.target.value })}
                    />



                    {/* <TextField
                        style={{ marginBottom: 5 }}
                        label="E-mail"
                        value={editUsuarioData.email}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        error={!validateEmail(editUsuarioData.email)}
                        helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
                    /> */}









                    <Button className={classes.button} style={{ margin: 15, }} onClick={() => { handleUpdate() }}>confirm</Button>

                </div>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
            </div>
            <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
                <>
                    <Worker workerUrl={workerUrl}>
                        <Viewer
                            fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance,
                            ]}

                        />
                    </Worker>
                </>


            </Modal>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user.user,
});

const mapDispatchToProps = {
    setUser,
    setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CodePage);


