import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Collapse, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import CloudUpload from '@material-ui/icons/CloudUpload';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { connect, ConnectedProps } from 'react-redux';
import store from '../redux/store';
import { getUsuarios, deleteUsuario, createUsuario, updateUsuario } from '../redux/usuarios/actions';
import { getAllInventoryStart, consultProductStart, getInventoriesByCategory, getInventoriesByFilter } from '../redux/produtos/actions';
import { getCategorias, } from '../redux/categorias/actions';
import { fetchEmpresas, } from '../redux/empresas/actions';
import { getImagesByReferencia, } from '../redux/referencias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ExpandLess, ViewList, Close } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import { client, AUTH_USER, CREATE_REFERRAL, getUsers, whoami, UPDATE_USER, CREATE_USER, getCategories, getCompanies, filterRecommendation, findProfileByUser, filterReferral, UPDATE_RECOMMENDATION } from '../graphqlClient/graphql'
import Notifications from './../components/notifications/Notifications';
import { Autocomplete, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, FormControlLabel, FormGroup, LinearProgress, Link, ListItemButton, ListSubheader, Stack, styled, Switch } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverConfig } from '../api/apiConfig';
import { useMutation } from '@apollo/client';
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { height } from '@mui/system';

/* import pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; */


const workerUrl = new URL(
  'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between!important',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    margin: "0 auto!Important",
    alignSelf: "center",
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignContent: 'center',
    flexWrap: 'nowrap',
    //alignItems: 'flex-start',
    // justifyContent: 'center',
    textAlign: 'center',
    border: '1px solid green',
    width: '80%!important',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  irButton: {
    color: 'white',

    margin: 5
  }
}));

const chartConfig = {
  backgroundColor: '#26872a',
  backgroundGradientFrom: '#43a047',
  backgroundGradientTo: '#66bb6a',
  color: (opacity = 1) => `#ffffff`,
  style: {
    borderRadius: 16
  }
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


type PropsFromRedux = ConnectedProps<typeof connector>;

const ScoutersPage: React.FC<PropsFromRedux> = ({ usuarios, isLoadingInventory, empresas, imagesByReferencia, consultedProduts, produtos, user }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const screenWidth: any = getWindowDimensions().width
  const screenHeight: any = getWindowDimensions().height
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
  const [addUsuarioModalOpen, setAddUsuarioModalOpen] = React.useState(false);
  const [newUsuarioName, setNewUsuarioName] = React.useState('');
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState('');
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
  const [newUsuarioEmail, setNewUsuarioEmail] = React.useState('');
  const [newUsuarioTelefone, setNewUsuarioTelefone] = React.useState('');
  const [newUsuarioEmpresa, setNewUsuarioEmpresa] = React.useState('');
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [category, setCategory] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [categoriesToShow, setCategoriesToShow] = React.useState([{ nome: 'Todas', _id: 0 }]);

  const [total, setTotal] = React.useState<any>(0);
  const [inventoriesList, setInventoriesList] = React.useState<any>([]);
  const [tagsNotFound, setTagsNotFound] = React.useState<any>([]);
  const [tagsFound, setTtagsFound] = React.useState<any>([]);
  const [tagsLost, setTagsLost] = React.useState<any>([]);
  const [tagsEquals, setTagsEquals] = React.useState<any>([]);
  const [dataChart, setDataChart] = React.useState<any>([]);


  const [visible, setVisible] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [limit, setLimit] = React.useState(0);
  const permissoesList = ['administrador', 'gerente', 'basico']
  const [rows, setRows] = React.useState<any>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({ key: 0, value: 'Filtro por unidade' });
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
  const [selectedProduto, setSelectedProduto] = React.useState<any>({ key: 0, value: 'Filtro por produto' });
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [assignedReferrals, setAssignedReferrals] = React.useState<any>(0);
  const [referralsToBeReviewed, setReferralsToBeReviewed] = React.useState<any>(0);
  const [openPositions, setOpenPositions] = React.useState<any>(0);
  const [companies, setCompanies] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [referralData, setReferralData] = React.useState<any>({});
  const [recommendations, setRecommendations] = React.useState<any>([]);
  const [referrals, setReferrals] = React.useState<any>([]);
  const [selectedScout, setSelectedScout] = React.useState<any>({});
  const [scoutInfo, setScoutInfo] = React.useState<any>(false);
  const [selectedRecommendation, setSelectedRecommendation] = React.useState<any>({});
  const [requestPanel, setRequestPanel] = React.useState<any>(false);

  const [openPdf, setOpenPdf] = React.useState<any>(false);
  const [openReferral, setOpenReferral] = React.useState<any>(false);
  const [expanded, setExpanded] = React.useState<any>('');
  const [typeViewer, setTypeViewer] = React.useState<any>('recommendation');
  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };
  const apiRef = useGridApiRef();

  const [CreateReferralMutation, { data }] = useMutation(CREATE_REFERRAL);
  const [UpdateRecommendationMutation, { data: updateRecommendationInput }] = useMutation(UPDATE_RECOMMENDATION);


  const updateMutation = async (referralId: any) => {
    setLoading(true);
    const dataToSend = {
      _id: selectedRecommendation._id,
      status: "Referred to HR",
      details: "Referred to HR by "+ user.name,
      referral: referralId,
      scouter: user._id
    }
    try {

      const updateRecommendationInput = dataToSend
      console.log('updateRecommendationInput', dataToSend)

      const result = await UpdateRecommendationMutation({
        variables: {
          updateRecommendationInput
        },
        onCompleted: ({ updateRecommendation }) => {

          console.log('updateRecommendation== > ', updateRecommendation);
          const notData = {
            type: 'success',
            message: `Referral to ${updateRecommendation?.company?.name} Created Successfully!`,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }
          //checkReferrals(user)
          //Notifications(notData)
          setLoading(false);
          checkRecommendations(user)
          //navigate('/scout')
          //setCompany(updateUser);
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2500,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Error at updateRecommendation", error.message)
      setLoading(false);
    }
  }


  const handleAddReferral = async () => {
    const recom: any = selectedRecommendation
    if (!referralData.message || !referralData.email) {
      const notData = {
        type: 'error',
        message: `Please enter the e-mail of HR company and fill the message field`,
        title: 'wescout',
        timeOut: 2000,
        callback: () => { },
        priority: true,
      }


      Notifications(notData)
      return;
    }
    setLoading(true);
    const dataToSend = {
      scouter: user._id,
      scout: recom.scout._id,
      active: true,
      company: recom.company._id,
      recommendation: recom._id,
      joblink: recom.link,
      message: referralData.message,
      pdf: editUsuarioData.pdf,
      email: referralData.email,
      status: 'active',
      token:'abc',

    }
    console.log('dataToSend referral', dataToSend)
    /* setLoading(false);
    return; */
    // dataToSend.id = dataToSend._id


    try {

      const createReferralInput = dataToSend
      console.log('createReferralInput', dataToSend)

      const result = await CreateReferralMutation({
        variables: {
          createReferralInput
        },
        onCompleted: ({ createReferral }) => {

          console.log('referral created== > ', createReferral);
          const notData = {
            type: 'success',
            message: `Referral to ${createReferral?.company?.name} Created Successfully!`,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }

          checkReferrals(user)
          Notifications(notData)
          setLoading(false);
          updateMutation(createReferral._id)
          setRequestPanel(false)
          setOpenReferral(false)
          //navigate('/scout')
          //setCompany(updateUser);
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2500,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Error at create referral", error.message)
      setLoading(false);
    }

  };

  const checkRecommendations = async (u: any) => {
    setLoading(true)
    const objToSend = {
      //startDate,
      //endDate,
      //empresa:empresa
      //category:selectedCategory?._id || '',
      "company": u.company?._id || '',
      "active": true,
      "status": "Under Review"

    }
    console.log('objToSend checkRecommendations recommendations', objToSend)

    const result: any = await filterRecommendation(objToSend)
    console.log('filterRecommendation result ==>>', result)
    setRecommendations(result)
    setReferralsToBeReviewed(result.length)
    setLoading(false)
    if (result.length == 0) {
      setTypeViewer('referral')
      //handleAddRecommendations()
    } else {
      //setReferralsRemaining(result[0].amount)
      //setRecommendations(result)
    }
    return result
  }



  const checkReferrals = async (u: any) => {
    const objToSend = {
      //startDate,
      //endDate,
      //empresa:empresa
      //category:selectedCategory?._id || '',
      //"company": u.company?._id || '',
      "scouter": u._id
      //"active" : true

    }
    console.log('objToSend get referrals', objToSend)

    const result: any = await filterReferral(objToSend)
    console.log('filterReferral result ==>>', result)
    setReferrals(result)
    setAssignedReferrals(result.length)
    setRows(result)
    //setReferralsToBeReviewed(result.length)
    if (result.length == 0) {
      console.log('no referrals')
      
      //handleAddRecommendations()
    } else {
      //setReferralsRemaining(result[0].amount)
      //setRecommendations(result)
    }
    return result
  }

  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('resutl ===>', result)

    if (result?.length) {
      setCategories(result)
    }
  }

  const getCompaniesAsync = async () => {

    const result: any = await getCompanies()
    console.log('resutlgetCompanies ===>', result)

    if (result?.length) {
      setCompanies(result)
    }
  }

  /* useEffect(() => {
    changeFilter()

  }, [selectedUser, selectedCategory, selectedProduto, startDate, endDate]); */


  let timer: any
  useEffect(() => {
    console.log('limit useEffect', limit)
    setProgress(limit)
    //if(limit == 0){
    //clearInterval(timer)
    return
    //}

    /* timer = setInterval(() => {
      console.log('progress',progress)
      console.log('limit',limit)
       
        setProgress((prevProgress) => 
        {
          console.log('prevProgress',prevProgress)
          const inc = limit
          if(prevProgress>=limit || inc>=limit){
            console.log('limpou',timer)
            clearInterval(timer);
            timer = 0
          }
          
          return(inc >= limit ? limit : inc)
        });
    }, 100); */

    //return clearInterval(timer)
  }, [limit]);

  /*   useEffect(() => {
      console.log('inventories ===>', inventories)
      if(inventories.length){
        const order = (a:any,b:any)=>{
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
        getImagesByReferencia(0)
        const newOrder = inventories.slice().sort(order)
        console.log('newOrder', newOrder)
        setInventoriesList(newOrder)
        setRows(newOrder)
      }
      
      //console.log('empresas' , empresas)
    }, [inventories]); */


  useEffect(() => {
    if (user?.name) {
      console.log('checkReferrals', user)
      checkReferrals(user)
      checkRecommendations(user)
    }
  }, [user]);
  useEffect(() => {
    if (selectedScout?.name) {

      getProfileByUserAsync(selectedScout._id)
    }
  }, [selectedScout]);


  const getProfileByUserAsync = async (userId: any) => {
    try {
      setLoading(true)
      const profile: any = await findProfileByUser(userId);
      console.log('profile ==>>', profile)
      setLoading(false)
      setEditUsuarioData(profile)
    }
    catch (error) {
      console.log("Erro no getUser >>>>>", error)
    }
  }



  const handleDeleteClick = (UsuarioId: string) => {
    console.log('UsuarioId', UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };

  const getCategoriaNome = (id: any) => {
    const nome = categories.filter((item: any) => id == item._id)
    return nome.length ? nome[0].nome : id

  }



  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }


  const checkItem = (item: any) => {
    let achou: any = false
    //console.log(item)
    for (var i in consultedProduts) {
      //console.log('i checkItem',i)
      /* const search = tagsFound.filter((item:any)=>{
        item._id == i
      })
      search.length ? achou = true : null */
      if (i == item) achou = consultedProduts[i]
    }
    //console.log("achou ==>> ",achou)
    return achou


  }

  interface Props {
    title: any
    subTitle: any
    itensList: []
    type: any
  }


  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"

          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const ImageComponent = (obj: any) => {
    console.log('ImageComponent', obj)
    //console.log('editados',editados)
    //setEditReferenciaData(obj.obj.row)
    let caminho: any = '';
    let image: any = '';
    let referencia = obj.obj?.referencia || ''
    if (referencia && imagesByReferencia?.length) {
      caminho = imagesByReferencia.filter((item: any) => referencia == item.idReferencia)[0]
      //console.log(caminho)
      if (caminho) {
        image = serverConfig.dev.url + 'imagens/imagem/' + caminho._id;
        return (
          <ListItemAvatar>
            <Avatar sx={{ width: 48, height: 48 }} variant='rounded' src={image}></Avatar>
          </ListItemAvatar>

          /*  {
             obj.obj?.nome ? (
               <Avatar.Accessory name='verified' backgroundColor={'white'} type='materialIcons' color={'#008800'}  size={20} />
             ) : (
               <Avatar.Accessory name='backup' backgroundColor={'white'} type='materialIcons' color={'#008800'}  size={20} />
             )
           } */



        )
        //console.log(image)

      } else {
        return (
          // <Avatar  icon={  { name: 'report-off', type:'materialIcons', color:'#008800', size:30, } } >
          //   <Avatar.Accessory backgroundColor={'white'} color={'#008800'} name='verified' type='materialIcons'  size={20}  />
          // </Avatar>
          <ListItemAvatar>
            <Avatar sx={{ width: 56, height: 56 }} variant='rounded' ><ImageIcon></ImageIcon></Avatar>
          </ListItemAvatar>


        )
      }

    } else {
      {/* <IconButton onClick={
          ()=>{
            //handleEditReferencia(obj.obj.row)
            }
          }>
          <ImageSearch />
        </IconButton> */}
      return (
        <ListItemAvatar>
          <Avatar><ImageIcon></ImageIcon></Avatar>
        </ListItemAvatar>

      )
    }

  }

  const handlerCloseModal = () => {
    setVisible(false)
    setLimit(0)
    setProgress(0)
    clearInterval(timer);
    setSelectedScout({})
    setScoutInfo(false)
    setLoading(false)
  }

  const compare = (a: any, b: any) => {
    if (a.createdAt > b.createdAt)
      return -1
    else if (a.createdAt < b.createdAt)
      return 1
    else
      return 0

  }


  const changeCategoria = (cat: any) => {

    console.log('categoria id: ', cat)

    if (cat == 0) {
      getAllInventoryStart()
    } else {
      //get(cat)
    }

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    //setEditProdutoData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
    },

    {
      field: 'scout',
      headerName: 'Scout',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.scout.name
    },

    {
      field: 'company',
      headerName: 'Company',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.company.name
    },
    {
      field: 'joblink',
      headerName: 'Job Link',
      width: 150,
      editable: false,
      //valueGetter: (params: GridValueGetterParams) => params.row.conteudo.productsByCategory.length
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 90,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const item = rows.filter((item: any) => item._id == id)[0]
        return [

          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom} title="Details" arrow>
              <IconButton className='iconDetail' color="primary" aria-label="Details" >
                <ViewHeadline />
              </IconButton>
            </Tooltip>}
            label="Details"
            className="textPrimary"
            onClick={() => {
              showRequest(item)
              //console.log(item)
            }}
            color="inherit"
          />,

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface AutocompleteOption {
    categoria: string;
  }

  const changeFilter = () => {

    const objToSend = {
      startDate,
      endDate,
      categoria: selectedCategory?._id || '',
      usuario: selectedUser?._id || '',
      produto: selectedProduto?._id || '',
    }
    //console.log('objToSend', objToSend)


  }

  const showRequest = async (refer: any) => {
    setReferralData(refer)
    const recom = refer.recommendation
    console.log('recom', recom)
    await getProfileByUserAsync(refer.scout._id)
    setSelectedRecommendation(recom)
    setSelectedScout(refer.scout)
    setRequestPanel(true)
    setOpenReferral(true)
    //handleAddReferral(recom)
    //setRequestPanel(true)

  }
  const acceptRequest = (recom: any) => {
    console.log('recom', recom)
    setSelectedRecommendation(recom)
    setSelectedScout(recom.scout)
    setRequestPanel(true)
    //handleAddReferral(recom)
  }
  const handleScoutInfo = (recom: any) => {
    console.log('recom', recom)
    setSelectedRecommendation(recom)
    setSelectedScout(recom.scout)
    setScoutInfo(true)
    //setRequestPanel(true)
    //handleAddReferral(recom)
    //setRequestPanel(true)

  }

  const CustomToolbar = () => (
    <input value={'limpar'} onChange={(event: any) => setNewUsuarioName(event.target.value)} />
  );

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            Scouter ({user?.company?.name || ''})
          </Typography>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}

          {/* <FormControl fullWidth>
              <InputLabel className={'text'} htmlFor="tipo-select">Itens por Unidade</InputLabel>
              <Select
                className={classes.select}
                value={selectedCategoria}
                onChange={(e:any) => {

                  setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          <Box sx={{ width: '100%', height: 80, }} style={{
            padding: '10px', paddingBottom: '10px',
            borderColor: 'green',
            borderWidth: '2px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
          >
            <Button variant="contained" className='button-referral' onClick={() => {
              console.log("rows", rows)
              setTypeViewer('referral')
            }} >
              Assigned Referrals
              <Avatar sx={{ bgcolor: '#31D841', marginLeft: '10px' }} aria-label="recipe">
                {assignedReferrals}
              </Avatar>

            </Button>
            <Button variant="contained" className='button-referral' onClick={() => setTypeViewer('recommendation')}  >
              Referrals  to be reviewed
              <Avatar sx={{ bgcolor: '#31D841', marginLeft: '10px' }} aria-label="recipe">
                {referralsToBeReviewed}
              </Avatar>

            </Button>
            {/* <Button variant="contained" className='button-referral'  >
                        Open positions
                            <Avatar sx={{ bgcolor: '#31D841' }} aria-label="recipe">
                                {openPositions}
                            </Avatar>

                        </Button> */}
          </Box>

          {/* <Box sx={{ width: '100%', alignItems: "center" }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignSelf: 'center', paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Button variant='outlined' onClick={() => setTypeViewer('recommendation')}>REFERRALS TO BE REVIEWED</Button>
            <Button onClick={() => {
              console.log("rows", rows)
              setTypeViewer('referral')
            }}>ASSIGNED REFERRALS</Button>
          </Box> */}

          <Box sx={{ width: '100%', alignItems: "center" }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignSelf: 'center', paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>


            {typeViewer == "referral" ?
              <>
                {rows.length ? (
                  <Box sx={{ width: '100%' }}>

                    <DataGrid
                      apiRef={apiRef}
                      loading={isLoadingInventory}
                      getRowId={(row: { _id: any; }) => row._id}
                      //checkboxSelection
                      disableRowSelectionOnClick

                      onClipboardCopy={(copiedString) => console.log(copiedString)}
                      unstable_ignoreValueFormatterDuringExport
                      autoHeight={true}
                      rows={rows}
                      columns={columns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'createdAt', sort: 'desc' }],
                        },

                        pagination: {
                          paginationModel: {
                            pageSize: 15,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      //checkboxSelection

                      //editMode="row"
                      rowModesModel={rowModesModel}
                      onRowModesModelChange={handleRowModesModelChange}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      // slots={{
                      //   toolbar: EditToolbar,
                      // }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          setRows, setRowModesModel, csvOptions: {
                            fileName: 'inventariosDataBase_',
                            delimiter: ';',
                            utf8WithBom: true,
                          },
                          columns: {
                            columnVisibilityModel: { ID: false, },
                          },
                          columnsPanel: {
                            getTogglableColumns,
                          },
                          printOptions: { hideToolbar: true, disableToolbarButton: false }
                        },
                      }}



                    //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },


                    />

                  </Box>
                ) : (<div style={{ margin: 15 }}>

                </div>)}
              </> :
              <>
                {recommendations && recommendations.length ? (
                  <div className={classes.listContainer}>
                    {recommendations.map((item: any) => (
                      <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                          avatar={
                            <Avatar sx={{ bgcolor: '#31D841' }} aria-label="recipe">
                              {item.scout.name.substring(0, 2)}
                            </Avatar>
                          }
                          action={
                            <IconButton aria-label="settings">
                              <MoreVertIcon />
                            </IconButton>
                          }
                          title={item.scout.name}
                          subheader={clearData(item.createdAt)}
                        />
                        <CardMedia
                          onClick={() => {
                            handleScoutInfo(item)
                          }}

                          width={150}
                          component="img"
                          height="150"
                          image={item.scout.image ? serverConfig.dev.url + 'images/' + item.scout.image : "https://robohash.org/" + item.name}
                          alt="scout image"
                        />
                        <CardContent>
                          <Typography variant="body2" style={{ marginTop: 5 }} >
                            <Link className='roxo' href={`${item.link}`} target="_blank" underline="hover">
                              {'Job link'}
                            </Link>
                          </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                          {/* <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton> */}

                          <Button variant='contained' className={classes.irButton} onClick={() => {

                            handleScoutInfo(item)
                          }}>Professional info</Button>

                          <ExpandMore
                            expand={expanded == item._id ? true : false}
                            onClick={() => { handleExpandClick(item._id) }}
                            aria-expanded={expanded == item._id}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </CardActions>
                        <Collapse in={expanded == item._id} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Button className={classes.irButton} onClick={() => acceptRequest(item)}>Accept this request</Button>
                          </CardContent>
                        </Collapse>
                      </Card>
                    )

                    )}


                  </div>
                ) : (<div style={{ margin: 15 }}>
                  No requests found for the company {user?.company?.name || ''}
                  {/* <Tooltip TransitionComponent={Zoom} title="Clear" arrow>
                <IconButton onClick={()=>clearSelected()} size='small' className='iconDetail' color="primary" aria-label="Editar" >
                  <Close />
                </IconButton>
              </Tooltip> */}
                </div>)}
              </>

            }

          </Box>






          {/* {inventories.length ? (
            <div className={'listContainer'}>
              <List>
                {inventories.map((inventory: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <ListItemText
                    
                    primaryTypographyProps={{color:'#008800'}}
                    primary={getCategoriaNome(inventory.categoria)}
                    secondary={clearData(inventory.createdAt)}
                  />
                    
                    
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handlerSelectedItem(inventory)}>
                        <ViewList  />
                      </IconButton>
                      
                     
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog fullWidth={true} style={{ minWidth: '600px', width: '80vw', margin: "0 auto" }} open={requestPanel} onClose={() => {
        setRequestPanel(false)
        setOpenReferral(false)
      }} >
        {!openReferral ?
          <DialogTitle>Accept request - complete the following information </DialogTitle>
          : <DialogTitle>Referral complete </DialogTitle>}
        <DialogContent className={classes.modalContent}>
          <Typography variant="h4">{selectedRecommendation?.company?.name || ""}</Typography>

          {/* <Typography variant="body1">E-mail of {selectedRecommendation.company?.name || ""} HR</Typography> */}

          {!openReferral ?
            <>
              <TextField
                style={{ marginBottom: 2 }}
                variant="outlined"
                hiddenLabel
                label={`E-mail of ${selectedRecommendation?.company?.name} HR`}
                /* multiline
                maxRows={4} */
                value={referralData.email || ""}
                onChange={(e) => setReferralData({ ...referralData, email: e.target.value })}
              />
              <TextField
                style={{ marginBottom: 2 }}
                variant="outlined"
                hiddenLabel
                label="Intro text for your nomination"
                multiline
                maxRows={4}
                value={referralData.message || ""}
                onChange={(e) => setReferralData({ ...referralData, message: e.target.value })}
              />
            </> : <>


              <Typography variant="body2">send at: {clearData(referralData.createdAt)}</Typography>
              <Typography variant="body1">Thank you for your referral!</Typography>
              <Typography variant="body2">send to: {referralData.email}</Typography>
              <Typography variant="body2">message: {referralData.message}</Typography>
            </>

          }
          {/* <Typography variant="body1">Intro text for your nomination</Typography>
          <input
            
            type="text"
            value={referralData.message}
            onChange={(e) => setReferralData({ ...referralData, message: e.target.value })}
          /> */}
          <Typography variant="body1">Location: {editUsuarioData.location}</Typography>
          {/*  <input
            type="text"

            value={editUsuarioData.location}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, location: e.target.value })}
          /> */}
          <div>
            <Divider />
            <Button onClick={() => setOpenPdf(true)}>Curriculum(PDF Viewer)</Button>
            <Divider />
            <div style={{ fontSize: 18, margin: 15 }}>

              <TextField

                style={{ marginBottom: 5 }}
                variant="outlined"
                hiddenLabel
                label="bio"
                multiline
                maxRows={4}
                value={editUsuarioData.bio || ""}
              //  onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
              />
            </div>
            <Divider />
            <CircularProgressWithLabel style={{ margin: 15 }} value={80} />
            <Divider />
            <div style={{ fontSize: 18, margin: 15 }}>
              <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
                {'Job link'}
              </Link>

            </div>

            {limit ? (<CircularProgressWithLabel style={{ margin: 15 }} value={progress} />) : null}


          </div>
        </DialogContent>
        <DialogActions>
          {
            !openReferral ?
              <>
                <Button variant='outlined' onClick={() => {
                  setOpenReferral(false)
                  setRequestPanel(false)
                }}>Cancel</Button>

                <Button variant='contained' disabled={openReferral} onClick={handleAddReferral} color="secondary">
                  Send
                </Button>
              </>
              :
              <Button variant='outlined' onClick={() => {
                
                setRequestPanel(false)
                setOpenReferral(false)
              }}>Close</Button>
          }
        </DialogActions>
      </Dialog>


      <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
        <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

          <Button className='backbutton' onClick={() => setOpenPdf(false)}>Close</Button>




          <Worker workerUrl={workerUrl}>
            <Viewer
              fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
              plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
              ]}

            />
          </Worker>


        </div>
      </Modal>
      <Modal className={classes.modalContent} open={scoutInfo} onClose={() => handlerCloseModal()}   >

        <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

          <Button className='backbutton' onClick={() => handlerCloseModal()}>Close</Button>

          <div className={classes.listContainer}>
            <div style={{ margin: 15, }}>
              <div >
                <Typography >
                  <h2 style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                    {selectedScout.name}
                  </h2>
                </Typography>
                <Stack spacing={2} margin={1}>
                  Location : {editUsuarioData.location}
                </Stack>
                <Stack borderRadius={15} spacing={2} direction="row" justifyContent={'center'} alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <div style={{ fontSize: 18, margin: 15 }}>Professional Title: {editUsuarioData.professionalTitle}</div>

                  <div style={{ fontSize: 18, margin: 15 }}>Years Of Experience: {editUsuarioData.yearsOfExperience}</div>


                </Stack>

                <Stack spacing={2}>
                  <FormGroup>
                    <FormControlLabel control={<Switch disabled checked={editUsuarioData.employed} />} label="Employed" />

                  </FormGroup>
                </Stack>
                <Stack spacing={2}>
                  {editUsuarioData.pdf ?
                    <div style={{ color: 'white' }}>
                      <div>file uploaled: <span className='subtitle'>{editUsuarioData.pdf}</span> . </div>
                      <Button variant='outlined' onClick={() => setOpenPdf(true)}>Curriculum(PDF Viewer)</Button>
                    </div>

                    : null}
                </Stack>



                <div style={{ fontSize: 18, margin: 15 }}>

                  <TextField
                    style={{ marginBottom: 5 }}
                    variant="outlined"
                    hiddenLabel
                    label="bio"
                    multiline
                    maxRows={4}
                    value={editUsuarioData.bio || ""}
                    onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
                  />
                </div>
                <Divider />
                <CircularProgressWithLabel style={{ margin: 15 }} value={80} />
                <Divider />
                <div style={{ fontSize: 18, margin: 15 }}>
                  <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
                    {'Job link'}
                  </Link>

                </div>
                {/* <div style={{ fontSize: 18, margin: 15 }}>Location: {selectedRecommendation.location}</div> */}
                {/* <div style={{}}>Itens lidos: {tagsFound.length} </div> */}
                {limit ? (<CircularProgressWithLabel style={{ margin: 15 }} value={progress} />) : null}
                {/* <div style={{ alignSelf: 'center', marginTop: 15 }}>
                  <PieChart
                    series={[
                      {
                        data: dataChart
                      },
                    ]}
                    width={400}
                    height={200}
                  />
                </div> */}

                {/* <VictoryPie
                      width={450}
                      height={380}
                      data={dataChart}
                      labels={({ datum }) => `${datum.y}: ${datum.label}`}
                      colorScale={["green", "red", "gold" ]}
                      animate={{
                        duration: 2000
                      }}
                      style={{
                        data: {
                          fillOpacity: 0.9, stroke: "#0af253", strokeWidth: 2, 
                        },
                        labels: {
                          fontSize: 14,  marginLeft:-10
                        }
                      }}
                    /> */}

              </div>
            </div>




          </div>

          {/* <div>
                //button to trigger printing of target component 
                <ReactToPrint
                  trigger={() => <Button>Print this out!</Button>}
                  content={() => componentRef}
                />

                
                <ComponentToPrint ref={(el) => (componentRef = el)} />
              </div> */}

        </div>
      </Modal>




    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuarios: state.usuarios.usuarios,
  user: state.user.user,
  isLoadingInventory: state.produtos.isLoadingInventory,

  consultedProduts: state.produtos.consultedProduts,
  produtos: state.produtos.produtos,
  inventories: state.produtos.inventories,
  imagesByReferencia: state.referencias.imagesByReferencia,
  isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas
});

const mapDispatchToProps = {
  fetchEmpresas,
  getCategorias,
  getUsuarios,
  deleteUsuario,
  createUsuario,
  updateUsuario,
  getAllInventoryStart,
  getInventoriesByCategory,
  getInventoriesByFilter,
  consultProductStart,
  getImagesByReferencia,

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScoutersPage);
