export const serverConfig = {
  dev: {
    //host: 'wescout',
    //url: 'https://host.greentag.com.br/',
    //url: 'http://85.31.235.240:3000/',
    url: 'https://api.wescout.me/seguro/',
    // url: 'http://localhost:3000/',
    //url: 'http://200.146.196.137:3000/',
  }
  ,
  production: {
    host: 'rfid',
    url: 'https://api.wescout.me/seguro/',
  },
  pathUseCases: {
    auth: {
      authLogin: 'auth/login',
      authRefresh: 'auth/refresh',
    },
    sales: { salesPayment: 'sales/payments' },
    empresas: {
      getEmpresas: 'empresas',
      createEmpresa: 'empresas',
      updateEmpresa: (empresaId: string) => `empresas/${empresaId}`, //PATCH Atualiza 
      getEmpresaById: (empresaId: string) => `empresas/${empresaId}`, //get  
      deleteEmpresa: (empresaId: string) => `empresas/${empresaId}`, //DELETE Exclui.
      getImages: 'imagens/consultar',
    },
    referencias: {
      getReferencias: 'referencia', // get
      createReferencia: 'referencia', // post
      updateReferencia: (refId: string) => `referencia/${refId}`, // PATCH Atualiza 
      getReferenciaById: (refId: string) => `referencia/${refId}`, // get  
      deleteReferencia: (refId: string) => `referencia/${refId}`, // DELETE Exclui.
      getImages: 'imagens/consultar',
    },
    usuarios: {
      getUsuarios: 'usuarios',
      createUsuario: 'usuarios',
      updateUsuario: (usuarioId: string) => `usuarios/${usuarioId}`, //PATCH Atualiza 
      deleteUsuario: (usuarioId: string) => `usuarios/${usuarioId}`, //DELETE Exclui .
      getImages: 'imagens/consultar',
      getUsuariosByFilter: 'usuarios/consultar',
    },
    produtos: {
      createProdutos: 'produtos',
      insertCode: 'produtos/rfid/add', //POST Inserindo código de barras ao produto.
      updateCode: 'produtos/rfid/update',//POST Altera código de barras ao produto.
      deleteCode: 'produtos/rfid/remove',//POST Remove código de barras ao produto.
      searchCode: 'produtos/rfid/find',//POST Pesquisa o produto por codigo de barras.
      getProdutos: 'produtos',//GET Listar todos os produtos.
      findProduto: 'produtos/{id}',//GET Listar um produto pelo id.
      consultarTipo: 'movprod/consultar/tipo',//post Listar produtos agregados por tipo agregados por data.
      updateProduto: (produtoId: string) => `produtos/${produtoId}`, //PATCH Atualiza PRODUTO
      updateProdutos: `produtos`, //PATCH Atualiza os PRODUTOs
      deleteProduto: (produtoId: string) => `produtos/${produtoId}`, //DELETE Exclui o produto.
      getProductsByCategory: (produtoId: string) => `categorias/produtos/${produtoId}`, //get produto por categoria.
      movProd: 'movprod', //POST
      getLogs: (produtoId: string) => `movprod/${produtoId}`, //get logs do produto.
      uploadImage: 'imagens',// POST upload image.
      getImages: 'imagens/consultar',
      getSelectedImage: (imagemId: string) => `imagens/imagem/${imagemId}`, //get retorna imagem
      getProdutosByFilter: 'produtos/consultar', //post produtos por filtros.
      getLogsByFilter: 'movprod/consultar', //post log por filtros.
      getProdutosContador: `produtos/contador`, //post contador by categoria

    },
    inventory: {
      getAllInventories: 'inventario',
      createInventory: 'inventario',
      findInventory: 'inventario/{id}',//GET Listar um produto pelo id.
      updateInventory: (InventoryId: string) => `inventario/${InventoryId}`,
      deleteInventory: (InventoryId: string) => `inventario/${InventoryId}`,
      getInventoriesByFilter: 'inventario/consultar', //post inventarios por filtros.
      getInventoriesByCategory: (categoryId: string) => `categorias/inventarios/${categoryId}`, //get produto por categoria.
      getInventariosContador: `inventario/contador`, //post contador inventario by produto
    },
    categorias: {
      getCategorias: 'categorias',
      createCategoria: 'categorias',
      updateCategoria: (categoriaId: string) => `categorias/${categoriaId}`,
      deleteCategoria: (categoriaId: string) => `categorias/${categoriaId}`,
    },
    notificacoes: {
      getNotificacoes: 'notificacoes',
      getTokens: 'usuarios/gettokens',
      createNotificacao: 'notificacoes/sendpush',
      updateNotificacao: (notificacaoId: string) => `notificacoes/${notificacaoId}`,
      deleteNotificacao: (notificacaoId: string) => `notificacoes/${notificacaoId}`,
    },

    userApp: {
      createNewUser: 'users',
      // updateUser: (userId: string) => `users/${userId}`,
      // deleteUser: (userId: string) => `users/${userId}`,
      // getUser: (userId) => `users/${userId}`,
      updateUser: 'user',
      deleteUser: 'user',
      getUser: 'usuarios/who',
      // getAllUsers: 'users',
      followUser: `follow`,
      getAllFollowersFromUser: (userId: string) =>
        `users/${userId}/followers`,
      getAllMyFollowers: (userId: string) => `users/${userId}/following`,
    },
  },
};
