import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/companies/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ImageSearch } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Divider, LinearProgress, ListItemButton, ListSubheader, Stack } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


import {client, AUTH_USER, getUsers, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY,getCategories, getCompanies, UPDATE_COMPANY} from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import Notifications from './../components/notifications/Notifications';
import { UploadFile } from '@mui/icons-material';
import { serverConfig } from './../api/apiConfig';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const CompaniesPage: React.FC<PropsFromRedux> = ({user}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [CreateCompanyMutation, { data }] = useMutation(CREATE_COMPANY);
  const [UpdateCompanyMutation, { data:updateCompanyData }] = useMutation(UPDATE_COMPANY);
  const [RemoveCompanyMutation] = useMutation(REMOVE_COMPANY);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [addCompanyModalOpen, setAddCompanyModalOpen] = React.useState(false);
  const [newCompany, setNewCompany] = React.useState<any>({});

  const [editData, setEditData] = React.useState<any>({});
  const [companies, setCompanies] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  
  const [editCompanyModalOpen, setEditCompanyModalOpen] = React.useState(false);
  const [isLoadingCompanies, setIsLoadingCompanies] = React.useState(false);
  const [isLoadingCompaniesByFilter, setIsLoadingCompaniesByFilter] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador','gerente','basico']

  const [rows, setRows] = React.useState(companies);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});

  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<any>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [uploadScreen, setUploadScreen] = React.useState<boolean>(false);
  const [imageToOpen, setImageToOpen] = React.useState<any>('');
  const apiRef = useGridApiRef();
 
  useEffect(() => {
    if(companies.length&&categories.length){
      setRows(companies)
      apiRef.current.setColumnVisibility('_id', false);
    }
    
  }, [companies, categories]);

   useEffect(() => {
    if(!isLoadingCompanies&&!isLoadingCompaniesByFilter&&categories.length){
      console.log("change filter ", useEffect)
      changeFilter()
    }
    
  }, [selectedUser, selectedCategory ,startDate, endDate, ]);  


const getCategoriesAsync=async ()=>{
  
  const result:any = await getCategories()
  console.log('categories ===>',result)
  
  if(result?.length){
    setCategories(result)
  }
  return result;
}

const getCompaniesAsync=async ()=>{

  const result:any = await getCompanies()
  console.log('resutl ===>',result)
  
  if(result?.length){
    setCompanies(result)
    setIsLoadingCompanies(false)
    setIsLoadingCompaniesByFilter(false)
  }
}
  /* useEffect(() => {
    if(isLoadingCompanies){
      setLoading(true)
    }
    if(loading && !isLoadingCompanies){
      //getCompanies();
      setLoading(false)
    }
    console.log('isLoadingCompanies ==> ',isLoadingCompanies)
    
  }, [isLoadingCompanies]); */
  useEffect(() => {
    
  const start = async ()=>{
    setIsLoadingCompanies(true)
    await getCategoriesAsync();
    getCompaniesAsync();
  }
  start()
    
},[]);

const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
  if (e.target.files) {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))
  }
};
const clearImage = () => {
    setImage('')
  
};

  

  const handleDeleteClick = (CompanyId:any) => {
    console.log('CompanyId',CompanyId)
    
    setSelectedCompany(CompanyId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    console.log('selectedCompany',selectedCompany)
    try{
      
      const id:string = selectedCompany
      console.log('id', id)
      
      const result = await RemoveCompanyMutation({
        variables: {
          id
        },
        onCompleted: ({removeCompany} ) => {
          
          console.log('company deleted== > ',removeCompany);
          const notData = {
            type : 'success',
            message : `Company removed Successfully!` ,
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          getCompaniesAsync()
          Notifications(notData)
          
          //navigate('/login')
          //setCompany(updateUser);
        }
      });
    } catch(error:any){
      const notData = {
        type : 'error',
        message : error.message,
        title : 'wescout',
        timeOut : 2500,
        callback : ()=>{},
        priority : true,
      }
      Notifications(notData)
      console.log("Erro ao deletar company", error)
  
    }
    
    setDeleteConfirmationOpen(false);
  };

  const clearNewCompany =() => {
    setNewCompany({})

  }

  const handleAddCompany = async () => {
    const dataToSend = {...newCompany, createdBy: user._id}
    console.log('dataToSend', dataToSend)
    if(file){
      console.log(file)
      dataToSend.image = file;
      //dataToSend.contador = (contador+1);
    } 
    // dataToSend.id = dataToSend._id
    delete dataToSend.__typename
    console.log('dataToSend',dataToSend)
    if(typeof dataToSend.image == 'string') delete dataToSend.image
    try{
      
      const createCompanyInput = dataToSend
      console.log('createCompanyInput', dataToSend)
      
      const result = await CreateCompanyMutation({
        variables: {
          createCompanyInput
        },
        onCompleted: ({createCompany} ) => {
          
          console.log('company created== > ',createCompany);
          const notData = {
            type : 'success',
            message : `Company ${createCompany?.name} Created Successfully!` ,
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          getCompaniesAsync()
          Notifications(notData)
          
          //navigate('/login')
          //setCompany(updateUser);
        }
      });
    } catch(error:any){
      const notData = {
        type : 'error',
        message : error.message,
        title : 'wescout',
        timeOut : 2500,
        callback : ()=>{},
        priority : true,
      }
      Notifications(notData)
      console.log("Erro ao criar usuário", error)
  
    }
    setAddCompanyModalOpen(false);
    clearNewCompany()
  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  const validateCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Verifica se possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    
    // Validação do primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[12]) !== digit) {
      return false;
    }
    
    // Validação do segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[13]) !== digit) {
      return false;
    }
    
    return true;
  };
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  const handleEditCompany = (data:any) => {
    console.log('data selecionada ==>> ', data)
    setEditData(data);
    setEditCompanyModalOpen(true);
  };
  
  const handleUpdateCompany = async (data? : any) => {

    let dataToSend = {...editData}
    if(!dataToSend._id) dataToSend = {...data}
    console.log('dataToSend',dataToSend)

    if(file){
      console.log(file)
      dataToSend.image = file;
      //dataToSend.contador = (contador+1);
    } 
    // dataToSend.id = dataToSend._id
    if(dataToSend.__typename)
      delete dataToSend.__typename
    if(dataToSend.createdAt)
      delete dataToSend.createdAt
    if(dataToSend.isNew!="undefined") delete dataToSend.isNew;
    
    if(dataToSend.createdBy)
      delete dataToSend.createdBy
    if(dataToSend.category&& typeof  dataToSend.category === "object")
      delete dataToSend.category

    //console.log('dataToSend',dataToSend)
    if(typeof dataToSend.image == 'string') delete dataToSend.image
    try{
      
      const updateCompanyInput = dataToSend
      console.log('updateCompanyInput', dataToSend)
      
      const result = await UpdateCompanyMutation({
        variables: {
          updateCompanyInput
        },
        onCompleted: ({updateCompany} ) => {
          
          console.log('company updated== > ',updateCompany);
          const notData = {
            type : 'success',
            message : `Company ${updateCompany?.name} Updated Successfully!` ,
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          setEditData({});
          setEditCompanyModalOpen(false);
          getCompaniesAsync()
          Notifications(notData)
          
          //navigate('/login')
          //setCompany(updateUser);
        }
      });
    } catch(error:any){
      const notData = {
        type : 'error',
        message : error.message,
        title : 'wescout',
        timeOut : 2500,
        callback : ()=>{},
        priority : true,
      }
      Notifications(notData)
      console.log("Erro ao criar usuário", error)
  
    }
    
    //clearNewCompany()
    //updateCompany(dataToSend)
    setEditData({});
    setEditCompanyModalOpen(false);
    // Lógica para atualizar a Company com os dados do modal
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };



  //init DataGrid dependencies

  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    if(newRow.isNew){
      delete newRow.isNew
    }
    setEditData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    handleUpdateCompany(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleClickImage = ()=>{
    setOpen((previousOpen) => !previousOpen);
  }

  const ImageComponent = (obj: any)=>{
    console.log(obj)
    //setEditReferenciaData(obj.obj.row)
    if(obj.obj.image){
      const imagePath = serverConfig.dev.url + 'images/' + obj.obj.image;
      return (
        <div style={{alignSelf:'center',margin:"0 auto"}} onClick={()=>{
          //console.log(imagePath)
          setImageToOpen(imagePath)
          console.log(imageToOpen)
          handleClickImage()
        }}>
          <img style={{objectFit:'cover'}} height={53}  src={imagePath} />
        </div>
        
      )
    }else{
      return(
        <IconButton onClick={
          ()=>{
            setEditData(obj.obj)
            setUploadScreen(true)

            //handleEditReferencia(obj.obj.row)
            }
          }>
          <ImageSearch />
        </IconButton>
      )
    }
    
  }


  const handleSaveClick = (id: GridRowId) => () => {
    const el = rows.filter((item:any)=>item._id == id)
    console.log('handleSaveClick', id)
    console.log('handleSaveClick el', el)
    setSave(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const getCategoryName = (id:any)=>{
    const name = categories.filter((item:any)=>id == item._id)
    return name.length ? name[0].name : id

  }
 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'imagem',
      headerName: 'Imagem',
      width: 80,
      editable: false,
      renderCell: (params) => <ImageComponent obj={params.row} />, // renderCell will render the component
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      type:'Date',
      width: 140,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    /* {
      field: 'email',
      headerName: 'E-mail',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    }, */
    {
      field: "category",
      headerName: 'Category',
      width: 160,
      editable: true,
      type: "singleSelect",
      valueOptions: categories,
      //valueSetter:  (value: any) => {console.log('value setter',value);return value.name},
      getOptionValue: (value: any) => value._id,
      getOptionLabel: (value: any) => {
        //console.log('value label',value);
        return value.name} ,
      valueGetter : (params: GridValueGetterParams)=> params.row.category[0]?._id || ''
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.createdBy.name || ''
    },
    

    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cat = companies.filter((item:any)=>item._id==id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          
          
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
                    <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                      <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

const changeFilter = async ()=>{

  const objToSend = {
    startDate,
    endDate,
    //empresa:empresa
    category:selectedCategory?._id || '',
    //usuario : selectedUser?._id || '',
    
  }
  setIsLoadingCompaniesByFilter(true)
  console.log('objToSend', objToSend)
  
  const result = await filterCompany(objToSend)
  console.log('filter result ==>>',result)
  //getProductsByFilter(objToSend)
  //getCompaniesByFilter(objToSend)
  setIsLoadingCompaniesByFilter(false)
  setCompanies(result)
  //setSelectedCategory({})
}
  
  return (
    <Container className={classes.container}>
      <Modal  onClick={(evt)=>{
                      setImageToOpen('')
                      handleClickImage()
                    }} open={open} style={{width:'100%', textAlign: 'center', display: 'flex',
            alignItems : 'center', justifyContent: 'space-between',backgroundColor:'white'}}>
            <Box>
              <Typography>
                clique para fechar
              </Typography>
              <Typography>
                {/* {editProdutoData.referencia?.nome} */}
              </Typography>
               <img width={500} style={{border:'2px solid white', maxHeight:'80vh'}}
                    src={`${imageToOpen}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageToOpen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={''}
                    loading="lazy"
                   
                  />
            </Box>
           

          </Modal>
      <div className='root'>
          <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            Companies ({companies.length})
          </Typography>
          {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
          <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddCompanyModalOpen(true)}>
            add 
          </Button>


          <Box sx={{  width: '100%', height:100, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              
            <Autocomplete
                getOptionLabel={(usu:any) => usu.name }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log('selectedCategory',newValue)
                     
                    if(newValue?.name){
                      setSelectedCategory(newValue)
                      //changeFilter()
                    }  else{
                      console.log('limpou')
                      setSelectedCategory({})
                      //changeFilter()
                    }
                    
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={categories}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Category" />}
              />
            
            </Box>
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          //views={["day", "month", "year"]}
                          //view={["day", "month", "year"]}
                          /* slots={{ toolbar: CustomToolbar }}
                          slotProps={{ toolbar: {} }} */
                          /* slotProps={{
                            actionBar: {
                              actions: ['clear'],
                            },
                          }} */
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
          </Stack>
          </Box>
          {companies.length&&categories.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingCompanies||isLoadingCompaniesByFilter}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'asc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'companiesDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            No records found
          </div>)}

          
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirm </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addCompanyModalOpen} onClose={() => setAddCompanyModalOpen(false)}>
        <DialogTitle>Add new company</DialogTitle>
        <DialogContent className={classes.modalContent}>
          
        <div className="file-upload-img">
                            {image ? <img src={image} alt="upload" /> : <UploadFile />}
                            {/* <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} /> */}
                            
                            {file? <div style={{color:'white'}}>file to upload: <span className='subtitle'>{file.name}</span> . </div>:null}
                            {/* <h3> {file?.name || "Choose a pdf file"}</h3> */}
                            <p>Maximum length 10mb</p>
                            {/* <input type="file" onChange={handleFileChange} /> */}
                            {
                                !file?.name||!image ? <label htmlFor="contained-button-file">
                                <input
                                    accept="application/image"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <Button variant="contained" component="span">
                                    Choose a image file
                                </Button>
                            </label> : <Button onClick={()=>clearImage()} variant="contained" component="span">
                                    clear
                                </Button>
                            }
                            
                        </div>

            
        
          <TextField
            label="Company name"
            value={newCompany.name}
            onChange={(e) => setNewCompany({...newCompany , name : e.target.value})}
          />
          <TextField
            label="Company description"
            value={newCompany.description}
            onChange={(e) => setNewCompany({...newCompany , description : e.target.value})}
          />
         
          
            
            {/* <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newCompanyEmpresa}
                onChange={(e:any) => setNewCompanyEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newCompanyPermissoes}
                onChange={(e:any) => setNewCompanyPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddCompanyModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddCompany} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={uploadScreen} onClose={() => setUploadScreen(false)}>
        <DialogTitle>Edit company</DialogTitle>
        <DialogContent className={classes.modalContent}>
          
        <div className="file-upload-img">
                            {image ? <img src={image} alt="upload" /> : <UploadFile />}
                            {/* <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} /> */}
                            
                            {file? <div style={{color:'white'}}>file to upload: <span className='subtitle'>{file.name}</span> . </div>:null}
                            {/* <h3> {file?.name || "Choose a pdf file"}</h3> */}
                            <p>Maximum length 10mb</p>
                            {/* <input type="file" onChange={handleFileChange} /> */}
                            {
                                !file?.name||!image ? <label htmlFor="contained-button-file">
                                <input
                                    accept="application/image"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <Button variant="contained" component="span">
                                    Choose a image file
                                </Button>
                            </label> : <Button onClick={()=>clearImage()} variant="contained" component="span">
                                    clear
                                </Button>
                            }
                            
                        </div>

            
        
          <TextField
            label="Company name"
            value={editData.name}
            onChange={(e) => setEditData({...editData , name : e.target.value})}
          />
          <TextField
            label="Company description"
            value={newCompany.description}
            onChange={(e) => setEditData({...editData , description : e.target.value})}
          />
        

          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadScreen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateCompany} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      { editCompanyModalOpen && editData.name ?
      <Dialog maxWidth="lg" open={editCompanyModalOpen} onClose={() => setEditCompanyModalOpen(false)}>
        <DialogTitle>Edit company information</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome do usuário"
            value={editData.name}
            onChange={(e) => setEditData({ ...editData, name: e.target.value })}
          />
          <TextField
            label="E-mail"
            value={editData.email}
            onChange={(e) => setEditData({ ...editData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editData.email)}
            helperText={!validateEmail(editData.email) && 'E-mail inválido'}
          />
          


            {/* <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newCompanyPermissoes}
                onChange={(e:any) => setNewCompanyPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          
          

            {/* <TextField
              label="Empresa"
              value={editData.empresa}
              onChange={(e) => setNewCompanyEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editData.empresa == ""}

              helperText={
                editData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}



        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditCompanyModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateCompany} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{/* <Modal open={editCompanyModalOpen} onClose={() => setEditCompanyModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editData.name}
      onChange={(e) => setEditData({ ...editData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editData.email}
      onChange={(e) => setEditData({ ...editData, email: e.target.value })}
    />
    <input
      type="text"
      value={editData.telefone}
      onChange={(e) => setEditData({ ...editData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editData.cnpj}
      onChange={(e) => setEditData({ ...editData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateCompany}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  
  user: state.user.user
  
  
  
});

const mapDispatchToProps = {
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompaniesPage);
