import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from '../redux/usuarios/actions';
import { setUser } from '../redux/user/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { deleteUsuario } from '../redux/usuarios/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverConfig } from '../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, MenuItem, Modal, Stack } from '@mui/material';
import { client, findProfileByUser, CONFIRM_CODE, AUTH_USER, CREATE_PROFILE, filterProfile, getUsers, whoami, UPDATE_USER, SEND_PASSWORD, UPDATE_PROFILE, NEW_PASSWORD, filterUser } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { EditOff, UploadFile } from '@mui/icons-material';
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Notifications from '../components/notifications/Notifications';
const workerUrl = new URL(
    'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
    import.meta.url,
).toString();
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems: 'center',
        height: '100%',
    },
    content: {

        textAlign: 'center',
    },
    modalContent: {
        margin: "0 auto!Important",
        alignSelf: "center",
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignContent: 'center',
        flexWrap: 'nowrap',
        //alignItems: 'flex-start',
        // justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid green',
        width: '80%!important',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
    form_content: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        aligContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    title: {
        marginBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const PasswordPage: React.FC<PropsFromRedux> = ({ setImageProfile, setUser, user }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { hash, pathname, search } = location;
    const paramsUrl = search.split('=')[1]
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
    const [params, setParams] = React.useState<any>(paramsUrl);
    const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
    const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>('');
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const permissoesList = ['administrador', 'gerente', 'basico']
    const [contador, setContador] = React.useState(0);
    const [file, setFile] = React.useState<File>();
    const [image, setImage] = React.useState<any>('');
    const [email, setEmail] = React.useState<any>('');
    const [open, setOpen] = React.useState<any>(false);
    const [update, setUpdate] = React.useState<any>(false);
    const [hasProfile, setHasProfile] = React.useState<any>(false);
    const [usuario, setUsuario] = React.useState<any>({});
    const [openPdf, setOpenPdf] = React.useState<any>(false);


    const [SET_PASSWORD, { data }] = useMutation(NEW_PASSWORD);
    const [UpdateProfileMutation, { data: updateProfileData }] = useMutation(UPDATE_PROFILE);

    useEffect(() => {
        if (params) {
            //console.log(params)
            getUser()
            //checkReferrals(params)
        }

    }, [params]);
    const getProfileByUserAsync = async (userId: any) => {
        setLoading(true)
        try {
            const profile: any = await findProfileByUser(userId);
            console.log('profile ==>>', profile)
            if (!profile) {
                setHasProfile(false)
            } else {
                setHasProfile(true)
                setEditUsuarioData(profile)
            }


        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    const getUser = async () => {
        setLoading(true)
        try {
            console.log('params', params)
            const localUser: any = await filterUser({ codeConfirmPassword: params });
            console.log('localUser ==>>', localUser)
            if (localUser.length) {
                setUsuario(localUser[0]);
                setUser(localUser[0])
            }

            //getProfileByUserAsync(user._id)
        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    /* useEffect(() => {

        getUser();

    }, []) */
    useEffect(() => {

        if (!user.confirmed) {
            setOpen(true)
        }

        if (user._id) {
            setEditUsuarioData(user)
        }
        if (user.email) {
            let emailMask = ''
            for (let i = 0; i < user.email; i++) {
                emailMask += '*'
            }
            setEmail(emailMask)
        }
        console.log('user code', user);
        //setEditUsuarioData(usuario)
        if (user?.image) {
            const imagePath = serverConfig.dev.url + 'images/' + user.image;
            setImage(imagePath)
            setImageProfile(imagePath)
            console.log('configurou o image profile')
        }
        //if (user && user.name) setUser(user)
    }, [user])

    const makePermissoes = () => {
        if (newUsuarioPermissoes == 'administrador') {
            return { admin: true }
        } else if (newUsuarioPermissoes == 'basico') {
            return { basic: true }
        } else if (newUsuarioPermissoes == 'gerente') {
            return { manager: true }
        } else {
            return {}
        }
    }

    const createUser: any = async (updateUserInput: any) => {
        setLoading(true)
        let variables = { "updateUserInput": updateUserInput }
        console.log('updateUserInput', updateUserInput)

        try {
            const userToken = await SET_PASSWORD({
                variables,
                onCompleted: ({ newPassword }) => {

                    console.log('user result code== > ', newPassword);
                    if (newPassword._id) {
                        setUser(newPassword)
                        const notData = {
                            type: 'success',
                            message: `Password changed successfully!`,
                            title: 'wescout',
                            timeOut: 1500,
                            callback: () => { },
                            priority: true,
                        }
                        
                            navigate('/login')
                        
                        //checkReferrals(user)
                        Notifications(notData)
                    } else {
                        const notData = {
                            type: 'Error',
                            message: `Invalid code!`,
                            title: 'wescout',
                            timeOut: 2000,
                            callback: () => { },
                            priority: true,
                        }
                        //checkReferrals(user)
                        console.log('codigo invalido')
                        Notifications(notData)
                    }

                    //getProfileByUserAsync(usuario._id)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error)

        }
        setLoading(false)

    }

    const updateUser: any = async (updateProfileInput: any) => {
        setLoading(true)
        let variables: {}
        variables = { updateProfileInput }

        try {
            const userToken = await UpdateProfileMutation({
                variables,
                onCompleted: ({ updateProfile }) => {

                    console.log('updateProfile result== > ', updateProfile);
                    setUsuario(updateProfile);
                    const notData = {
                        type: 'success',
                        message: `Account updated successfully!`,
                        title: 'wescout',
                        timeOut: 2500,
                        callback: () => { },
                        priority: true,
                    }
                    //checkReferrals(user)
                    Notifications(notData)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error)
            const notData = {
                type: 'error',
                message: error.message,
                title: 'wescout',
                timeOut: 2500,
                callback: () => { },
                priority: true,
            }
            Notifications(notData)

        }
        setLoading(false)

    }

    const handleUpdate = async () => {
        //setUpdate(true)
        console.log('editUsuarioData', editUsuarioData)
        const dataToSend = { _id: editUsuarioData._id, 
            codePassword: editUsuarioData.code , 
            password: editUsuarioData.password}
        //console.log(dataToSend)
        console.log('dataToSend', dataToSend)
        createUser(dataToSend)
    };

    const validateEmail = (email: any) => {
        return true
        // Implemente a lógica de validação do e-mail aqui
    };

    const validatePassword = (password: string | any[]) => {
        return password.length >= 6;
    };

    const validateConfirmPassword = (password: any, confirmPassword: any) => {
        return password === confirmPassword;
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container className={classes.container}>
            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Complete the requirements to join our Scouting Pool"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please, send your complete CV in PDF format to receive your feedback.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog> */}
            <div className={'content'}>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}

                {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
                {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
                <div className={classes.form_content}>
                    <Box sx={{ width: '100%', paddingTop: '15px' }}>
                        <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} >
                            <Typography className={'title'}>
                                Validate your e-mail
                            </Typography>
                        </Stack>
                        <Stack marginBottom={5} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} >
                            <Typography>
                                Please, digit the code sent to {editUsuarioData.email}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <TextField
                            style={{ margin: 5 }}
                            label="Senha"
                            value={newUsuarioPassword}
                            onChange={(e) => {
                                setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                                setNewUsuarioPassword(e.target.value)
                            }}
                            type="password"
                            error={!validatePassword(newUsuarioPassword)}
                            helperText={!validatePassword(newUsuarioPassword) && 'Password must be at least 6 characters long'}
                        />
                        <TextField
                            style={{ margin: 5 }}
                            label="Confirmar Senha"
                            value={newUsuarioConfirmPassword}
                            onChange={(e) => {
                                setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                                setNewUsuarioConfirmPassword(e.target.value)
                            }}
                            type="password"
                            error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
                            helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'Passwords do not match'}
                        />
                        <TextField
                            style={{ marginBottom: 5 }}
                            variant="outlined"
                            hiddenLabel
                            label="code"
                            multiline
                            maxRows={4}
                            value={editUsuarioData.code || ""}
                            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, code: e.target.value })}
                        />
                    </Box>




                    {/* <TextField
                        style={{ marginBottom: 5 }}
                        label="E-mail"
                        value={editUsuarioData.email}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        error={!validateEmail(editUsuarioData.email)}
                        helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
                    /> */}









                    <Button className={classes.button} style={{ margin: 15, }} onClick={() => { handleUpdate() }}>confirm</Button>

                </div>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
            </div>
            <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
                <>
                    <Worker workerUrl={workerUrl}>
                        <Viewer
                            fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance,
                            ]}

                        />
                    </Worker>
                </>


            </Modal>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user.user,
});

const mapDispatchToProps = {
    setUser,
    setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PasswordPage);


