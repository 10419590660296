import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email, Person, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from './../redux/usuarios/actions';
import { setUser } from './../redux/user/actions';
import { fetchEmpresas } from './../redux/empresas/actions';
import { deleteUsuario } from './../redux/usuarios/actions';
import { useNavigate, } from 'react-router-dom';
import { serverConfig } from './../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Autocomplete, Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, LinearProgress, MenuItem, Switch } from '@mui/material';
import { client, AUTH_USER, getUsers, whoami, UPDATE_USER, getCompanies, getCategories } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import Notifications from './../components/notifications/Notifications';
import borda from './../assets/images/comum/photo_profile.png'
import { minWidth, width } from '@mui/system';
import AccountPage from './AccountPage';
import planetPulse from 'planetpulse';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {

    textAlign: 'center',
  },
  form_content_container: {
    // maxHeight: 'calc(100vh - 184px)',
    overflowY: 'auto',
    marginTop: 2,
    display: 'flex',
    flexDirection: 'row',
    aligContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    minHeight: 'calc(100vh - 70px)',


  },
  form_content: {
    minWidth: 400,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'flex-start',
    alignItems: 'center',




  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const UsuarioPage: React.FC<PropsFromRedux> = ({ setImageProfile, setUser }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const allCountries = planetPulse.getAllCountries();
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
  const [userCompany, setUserCompany] = React.useState<any>({});
  const [userCategory, setUserCategory] = React.useState<any>({});
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const permissoesList = ['administrador', 'gerente', 'basico']
  const [contador, setContador] = React.useState(0);
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<any>('');
  const [update, setUpdate] = React.useState<any>(false);
  const [openConfirmEmail, setOpenConfirmEmail] = React.useState<any>(false);
  const [usuario, setUsuario] = React.useState<any>({});
  const [companies, setCompanies] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);

  const [UpdateUserMutation, { data }] = useMutation(UPDATE_USER);

  /* useEffect(() => {
    console.log("imagesByUser useEffect", imagesByUser)
    const imagesList:any = [];
    let cont = 0
    imagesByUser.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Usuario',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('imagesList ==>>> ', imagesList)
    console.log('cont ==>>> ', cont)
    setContador(cont)
    if(imagesList.length){
      const image = imagesList[0].image
      setImage(image)
      setImageProfile(image)
    }
    //setSlides(imagesList)
   
  },[imagesByUser]); */

  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('resutl ===>', result)

    if (result?.length) {

      setCategories(result)
    }
    getCompaniesAsync()
  }

  const getCompaniesAsync = async () => {

    const result: any = await getCompanies()
    console.log('resutl ===>', result)

    if (result?.length) {
      setCompanies(result)

    }
  }


  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]))
    }
  };
  const getUser = async () => {
    setLoading(true)
    try {
      const user: any = await whoami();
      console.log('user ==>>', user)
      if (user.category)
        setUserCategory(user.category)
      if (user.company)
        setUserCompany(user.company)
      setUsuario(user);
    }
    catch (error) {
      console.log("Erro no getUser >>>>>", error)
    }
    setLoading(false)
  }
  useEffect(() => {

    getUser();

  }, [])
  useEffect(() => {

    getCategoriesAsync();
    //setUserCompany(perm)
    setEditUsuarioData(usuario)
    if (usuario?.category) {
      setUserCategory(usuario.category)
    }
    if (usuario?.image) {
      const imagePath = serverConfig.dev.url + 'images/' + usuario.image;
      setImage(imagePath)
      setImageProfile(imagePath)
      console.log('configurou o image profile')
    }
    if (usuario && usuario.name) setUser(usuario)
  }, [usuario])


  const updateUser: any = async (updateUserInput: any) => {

    setLoading(true)

    try {
      const userToken = await UpdateUserMutation({
        variables: {
          updateUserInput: updateUserInput,

        },
        onCompleted: ({ updateUser }) => {

          console.log('user result== > ', updateUser);
          const notData = {
            type: 'success',
            message: 'user updating success',
            title: 'wescout',
            timeOut: 1400,
            callback: () => { },
            priority: true,
          }
          Notifications(notData)
          getUser()
          //setEditUsuarioData({updateUser})
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2000,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Erro ao atualizar usuário", error)

    }
    setLoading(false)
  }

  const handleUpdate = () => {
    setUpdate(true)
    setLoading(true)
    //console.log('editUsuarioData', editUsuarioData)
    const dataToSend = { ...editUsuarioData }
    //const permissoes = makePermissoes()
    //dataToSend.permissoes = permissoes
    if (file) {
      console.log(file)
      dataToSend.image = file;
      //dataToSend.contador = (contador+1);
    }
    if (userCompany?._id) {
      dataToSend.company = userCompany._id
    }
    if (userCategory?._id) {
      dataToSend.category = userCategory._id
    }
    // dataToSend.id = dataToSend._id
    delete dataToSend.__typename
    //console.log('dataToSend', dataToSend)
    if (typeof dataToSend.image == 'string') delete dataToSend.image
    setLoading(false)
    updateUser(dataToSend)
    console.log('datatosend ==> ', dataToSend)


  };

  const validateEmail = (email: any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };

  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  const handleCloseDialog = (event: Event | React.SyntheticEvent) => {

    setOpenConfirmEmail(false);
  };
  const handleChangeEmployed = (event: Event | React.SyntheticEvent) => {


    editUsuarioData.employed ? setEditUsuarioData({ ...editUsuarioData, employed: false }) : setEditUsuarioData({ ...editUsuarioData, employed: true })

  };
  const handleChangeEnableScoutingPool = (event: Event | React.SyntheticEvent) => {
    editUsuarioData.enableScoutingPool ? setEditUsuarioData({ ...editUsuarioData, enableScoutingPool: false }) : setEditUsuarioData({ ...editUsuarioData, enableScoutingPool: true })
  };
  const handleChangeUePassport = (event: Event | React.SyntheticEvent) => {
    editUsuarioData.uePassport ? setEditUsuarioData({ ...editUsuarioData, uePassport: false }) : setEditUsuarioData({ ...editUsuarioData, uePassport: true })
  };
  const handleChangeOpentorelocation = (event: Event | React.SyntheticEvent) => {


    editUsuarioData.opentorelocation ? setEditUsuarioData({ ...editUsuarioData, opentorelocation: false }) : setEditUsuarioData({ ...editUsuarioData, opentorelocation: true })

  };

  return (
    <Container className={classes.container}>
      <div style={{ width: '100vw', height: '100%' }}>
        {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
        <Dialog
          open={openConfirmEmail}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Enter the code that was sent to your email."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please, send your complete CV in PDF format to receive your feedback.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCloseDialog}>Not now</Button>
            <Button variant='contained' onClick={(e: any) => {
              handleCloseDialog(e)
              navigate('/account')

            }} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : null}
        {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
        <div className={classes.form_content_container}>

          {/* {file&&file.name ? (<div>{file && `${file.name} - ${file.type}`}</div>):null} */}
          <div className="file-upload">
            {/* <img src={uploadImg} alt="upload" /> */}
            <span className="user-info-column">
              <span style={{ height: '90px' }}>
                <div style={{ width: '85px', height: '85px', position: 'relative', }}>
                  <img width={85} src={borda} alt="" />
                </div>

                <Avatar sx={{ width: 81, height: 81, top: '-82px', left: "2px" }} alt={usuario.name} src={image || usuario.avatar} />
              </span>
              <span className="user-detail">
                <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '5px' }}>{usuario.name}</Typography>
                <Typography variant="h6" style={{ color: '#31D841' }}>{usuario.empresa?.name || 'Scout member'}</Typography>


              </span>
            </span>
            {/* <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} /> */}
            <h3> {file?.name || "Choose an image"}</h3>
            <p>Maximum length 10mb</p>
            <input type="file" onChange={handleFileChange} />
          </div>
          <div className={classes.form_content}>



            <div className='accountData'>

              <Typography variant="h5" style={{ fontWeight: 'bold', margin: '10px' }} >
                Account Data
              </Typography>
            </div>
            <TextField
              variant="standard"
              ///style={{marginBottom:5}}
              label="User name"
              value={editUsuarioData.name}
              onChange={(e) => setEditUsuarioData({ ...editUsuarioData, name: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ marginBottom: 5 }}
              label="E-mail"
              value={editUsuarioData.email}
              onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
              error={!validateEmail(editUsuarioData.email)}
              helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
            />

            {passwordOpen ? (
              <>
                <TextField
                  style={{ margin: 5 }}
                  label="Senha"
                  value={newUsuarioPassword}
                  onChange={(e) => {
                    setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                    setNewUsuarioPassword(e.target.value)
                  }}
                  type="password"
                  error={!validatePassword(newUsuarioPassword)}
                  helperText={!validatePassword(newUsuarioPassword) && 'Password must be at least 6 characters long'}
                />
                <TextField
                  style={{ margin: 5 }}
                  label="Confirm password"
                  value={newUsuarioConfirmPassword}
                  onChange={(e) => {
                    setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                    setNewUsuarioConfirmPassword(e.target.value)
                  }}
                  type="password"
                  error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
                  helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'Passwords do not match'}
                />
              </>

            ) : (<Button className={classes.button} onClick={() => setPasswordOpen(true)}>change password</Button>)

            }

            <Autocomplete
              getOptionLabel={(usu: any) => usu.name}
              value={{ name: editUsuarioData.country }}
              //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
              onChange={(event, newValue) => {
                console.log('selectedLocation', newValue)

                if (newValue?.name) {
                  /*  const countryInfo = planetPulse.getCountryByName(newValue?.name);
                   console.log(countryInfo); */
                  setEditUsuarioData({ ...editUsuarioData, country: newValue.name })
                  //setUserCategory(newValue)
                  //changeFilter()
                } else {
                  setEditUsuarioData({ ...editUsuarioData, country: '' })
                  //setUserCategory({})
                  //changeFilter()
                }


              }}
              disablePortal
              id="combo-box-usuario"
              options={allCountries}
              sx={{ width: '90%', margin: '5px 0' }}
              renderInput={(params: any) => <TextField {...params} label="Country where you are from" />}
            />

            <TextField
              style={{ marginBottom: 5 }}
              label="Phone"
              value={editUsuarioData.phone}
              onChange={(e) => setEditUsuarioData({ ...editUsuarioData, phone: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            /* error={!validateEmail(editUsuarioData.email)}
            helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'} */
            />

            <Autocomplete
              getOptionLabel={(usu: any) => usu?.name||'Category that interests you'}
              value={userCategory}
              //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
              onChange={(event, newValue) => {
                console.log('selectedCategory', newValue)

                if (newValue?.name) {
                  setUserCategory(newValue)
                  //changeFilter()
                } else {
                  console.log('limpou')
                  setUserCategory({})
                  //changeFilter()
                }


              }}
              disablePortal
              id="combo-box-category"
              options={categories}
              sx={{ width: '90%', margin: '5px 0' }}
              renderInput={(params: any) => <TextField {...params} label="Category that interests you" />}
            />
            {
              usuario.role == 'scouter' || usuario.role == 'admin' ?
                <Autocomplete
                  getOptionLabel={(usu: any) => usu?.name||'your company'}
                  value={userCompany}
                  //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                  onChange={(event, newValue) => {
                    console.log('selectedCompany', newValue)

                    if (newValue?.name) {
                      setUserCompany(newValue)
                      //changeFilter()
                    } else {
                      console.log('limpou')
                      setUserCompany({})
                      //changeFilter()
                    }


                  }}
                  disablePortal
                  id="combo-box-company"
                  options={companies}
                  sx={{ width: '90%', margin: '5px 0' }}
                  renderInput={(params: any) => <TextField {...params} label="Your company" />}
                />
                : null
            }

            <Typography >
              English Level
            </Typography>
            <ButtonGroup variant="outlined" aria-label="English Level">
              <Button className={editUsuarioData.englishLevel == 'B1' ? 'selected' : ''} onClick={(e) => setEditUsuarioData({ ...editUsuarioData, englishLevel: 'B1' })}>B1</Button>
              <Button className={editUsuarioData.englishLevel == 'B2' ? 'selected' : ''} onClick={(e) => setEditUsuarioData({ ...editUsuarioData, englishLevel: 'B2' })}>B2</Button>
              <Button className={editUsuarioData.englishLevel == 'C1' ? 'selected' : ''} onClick={(e) => setEditUsuarioData({ ...editUsuarioData, englishLevel: 'C1' })}>C1</Button>
              <Button className={editUsuarioData.englishLevel == 'C2' ? 'selected' : ''} onClick={(e) => setEditUsuarioData({ ...editUsuarioData, englishLevel: 'C2' })}>C2</Button>
            </ButtonGroup>

            <FormGroup>

              <FormControlLabel control={<Switch onChange={handleChangeEmployed} checked={editUsuarioData.employed} />} label="Employed" />
              <FormControlLabel control={<Switch onChange={handleChangeOpentorelocation} checked={editUsuarioData.opentorelocation} />} label="Open to relocation" />
              <FormControlLabel control={<Switch onChange={handleChangeEnableScoutingPool} checked={editUsuarioData.enableScoutingPool} />} label="Enable ScoutingPool" />
              <FormControlLabel control={<Switch onChange={handleChangeUePassport} checked={editUsuarioData.uePassport} />} label="UE passport" />
            </FormGroup>




            {/* <InputLabel htmlFor="permissao-select">select a category that interests you </InputLabel>
                <Select
                  value={userCategory}
                  onChange={(e: any) => setUserCategory(e.target.value)}
                  inputProps={{
                    name: 'permissao',
                    id: 'permissao-select',
                  }}
                >
                  {categories.map((category: any) => (
                    <MenuItem key={category} value={category}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select> */}



            <Button className={classes.button} style={{ margin: 15, }} onClick={() => { handleUpdate() }}>update</Button>





            {/* <TextField
              label="Empresa"
              value={editUsuarioData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editUsuarioData.empresa == ""}

              helperText={
                editUsuarioData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}
          </div>
          <div style={{ minWidth: '400px' }}>
            <Typography >
              <AccountPage />
            </Typography>
          </div>

        </div>


        <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {
  setUser,
  setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UsuarioPage);


